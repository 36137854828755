.footerWrapper {
    background: var(--footer--color);
    color: var(--footer--font);
    padding-top: 56px;
}

.footer {
    display: grid;
    grid-template-columns: 35% 20% 45%;
    gap: 30px;
}

.footerLogoImg {
    width: 120px;
    height: 50px;
}

.footer p {
    font-size: var(--font--size);
    line-height: 22px;
    margin: 0;
    margin-top: 12px;
}

.footer .socialIcons {
    display: flex;
    gap: 15px;
    margin-top: 18px;
}

.footer .menuItem {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 16px;
}

.footer .menuItem img {
    width: 10px;
    height: 8px;
}

.footer .socialLink img {
    width: 42px;
    height: 42px;
}

.footer a {
    color: var(--footer--font);
    text-decoration: none;
    font-size: var(--font--size);
}

.footer .subHeader {
    font-size: var(--font2--size);
    font-weight: 700;
    color: #ffffff;
}

.footerSubChild {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footerSubChildAlignment {
    display: flex;
    gap: 30px;
}

.width50 {
    width: 50%;
}

.alignPaymentDetails {
    display: flex;
    gap: 12px;
}

.footerDivider {
    border-top: 1px dotted #6a758e;
    padding: 30px;
    margin-top: 40px;
    text-align: center;
    font-size: var(--font3--size);
}

.mobileVersion {
    display: none !important;
}

@media (max-width: 991px) {
    .footer {
        grid-template-columns: 50% 50%;
    }

    .footer div:nth-child(3) {
        grid-column: span 2;
    }

    .footer .alignPaymentDetails {
        margin-top: 30px;
        justify-content: center;
    }
}

@media (max-width: 768px) {
    .footer {
        display: flex;
        flex-direction: column;
        padding-top: 40px;
    }

    .footer .socialIcons {
        display: none;
    }

    .footerSubChildAlignment {
        flex-direction: column;
    }

    .alignPaymentDetails {
        flex-wrap: wrap;
        border-top: 1px dotted #6a758e;
        border-bottom: 1px dotted #6a758e;
        padding: 20px 0;
    }

    .width50 {
        width: 100%;
    }

    .mobileVersion {
        display: flex !important;
        justify-content: center;
    }

    .footerDivider {
        border: none;
        margin-top: 0;
    }
}