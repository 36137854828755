/* .typing-animation {
  display: inline-block;
  font-family: "Courier New", Courier, monospace;
  font-size: 1.5rem;
} */

/* .cursor {
  display: inline-block;
  background: black; */
/* margin-left: 2px; */
/* animation: blink 1s step-end infinite;
  width: 1px;
} */

/* @keyframes blink {
  from,
  to {
    background: transparent;
  }
  50% {
    background: black;
  }
} */

/* New code */

.ani-search-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 35px;
  position: absolute;
  top: 75px;
  width: calc(30vw);
  z-index: 4;
}

.ani-search-input {
  font-size: var(--font2--size);
  color: var(--main--font--color);
  /* font-weight: 600; */
  padding: 10px 0 10px 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  /* width: 300px; */
  text-align: center;
  /* animation: blink-caret 0.75s step-end infinite; */
  border: none;
  caret-color: transparent;
  /* Hide the default cursor */
  position: relative;
  height: 25px;
}

.ani-search-input::after {
  content: "";
  display: inline-block;
  width: 2px;
  height: 1em;
  background: var(--main--font--color);
  position: absolute;
  /* right: 10px; Adjust based on padding */
  animation: blink-cursor 0.5s step-end infinite;
  margin-top: 0.2em;
}

@keyframes blink-cursor {

  from,
  to {
    background: transparent;
    border-color: transparent;
  }

  50% {
    background: var(--main--font--color);
    border-color: var(--main--font--color);
  }
}

@media (max-width: 768px) {
  .ani-search-input {
    font-size: 14px;
  }
}