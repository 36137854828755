.mobDisplay .destination-carousel-container {
    padding-bottom: 30px;
}

.mobDisplay .destination-carousel-container::-webkit-scrollbar {
    display: none;
}

.mobDisplay .destination-carousel-container>* {
    flex: 0 0 99%;
    max-width: 99%;
    min-width: 99%;
    margin: 0;
}

.mobDisplay .feature img,
.mobDisplay .carousel-item img {
    border-radius: 24px;
    max-height: 250px;
    min-height: 250px;
}

.mobDisplay .destination-carousel-container .carousel-item {
    position: relative;
}

.mobDisplay .carouselBtnWrapper {
    display: flex;
    gap: 20px;
    justify-content: center;
    position: inherit;
}

@media (max-width: 768px) {

    .reviewWrapper {
        padding: 50px 0 !important;
    }

    #blogSection {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    .destinationSection .mobCenter,
    .reviewWrapper .mobCenter,
    .blogSection .mobCenter {
        justify-content: center;
        text-align: center;
    }

    .mobCenter {
        text-align: center;
    }
}