.orgDateTabBtnDiv {
    margin: 0px 0px 18px 0px;
}

.orgDateTabUpCo {
    width: 20%;
    padding: 12px;
    background: var(--primary-color);
    border-radius: var(--main--border--radius);
    border: none;
    color: white;
    font-weight: 600;
    margin: 0px 30px 0px 0px;
    cursor: pointer;
}

.orgDateTabCom {
    padding: 12px;
    border-radius: var(--main--border--radius);
    border: 1px solid var(--main--border--color);
    color: var(--main--font--color);
    font-weight: 600;
    cursor: pointer;
    background: linear-gradient(
        85deg,
        white 0%,
        var(--main--background--color) 30%
    );
    margin: 0px 30px 0px 0px;
    width: 20%;
}

.orgDatesTabTable {
    width: 100%;
}

.orgDatesTabHead {
    background: var(--main--background--color);
    height: 40px;
}

.orgDatesTabRow {
    height: 40px;
    border-bottom: 1px solid gray;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
}

.orgDatesTabRow :hover {
    background: var(--main--background--color);
}

.orgDatesTabRow td {
    border-bottom: 1px solid var(--main--border--color);
}

.deleteBtn {
    border: none;
    background: #fff;
    cursor: pointer;
}

.confirmationText {
    font-size: 16px;
    font-weight: 500;
    padding: 16px 0;
}
