.orgRoomsHeadSeaDiv {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--main--border--color);
  margin: 0px 0px 20px 0px;
}

.orgRoomsH1 {
  font-size: var(--font4--size);
  font-weight: 700;
  padding: 10px 5px;
  margin: 0px 0px 0px 0px;
  color: var(--main--font--color);
}

.orgRoomsCreateBtn {
  height: 30px;
  margin: 10px 5px 10px 10%;
  width: 12%;
  padding: 0px 10px 0px 10px;
  background: linear-gradient(
    87deg,
    var(--main3--font--color) 30%,
    var(--linear--second--color) 80%
  );
  border-radius: var(--main--border--radius);
  border: none;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.orgRoomsCreateBtn:hover {
  background: var(--linear--second--color);
}

.orgRoomsCreateBtn:active {
  background: var(--main3--font--color);
}

.orgRoomsSelect {
  height: 40px;
  width: 30%;
  margin: 0 0 20px 0;
  font-size: var(--font4--size);
  color: var(--main--font--color);
  border-radius: var(--main2--border--radius);
  border: 1px solid var(--main--border--color);
  padding: 5px;
}

.orgRoomsTripStrap {
  grid-column: 2/7;
  padding: 0px 6% 30px;
}
