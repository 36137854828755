.font28 {
  font-size: var(--font6--size);
}

.font26 {
  font-size: var(--font8--size);
}

.font24 {
    font-size: var(--font9--size);
}

.card {
    border-radius: 12px;
    border: 1px solid #d5dde9;
    padding: 20px;
}

.cardContainer {
  display: grid;
  grid-template-columns: 55% 45%;
  gap: 24px;
}

.rightCard {
    background-color: #f3f6fb;
    height: max-content;
}

.leftCard img {
  width: 26%;
}

.leftCard .innerContainer {
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}

.leftCard .innerContainer .tripName {
    font-size: var(--font4--size);
    font-weight: 600;
    margin: 0 0 12px 0;
}

.formLabel {
    font-size: var(--font2--size);
    font-weight: 500;
}

.formAnsLabel {
    font-size: var(--font10--size);
    font-weight: 400;
    margin-top: 6px;
}

.formContent {
    border-bottom: 1px solid #d5dde9;
    padding: 12px 0;
}

.formArea {
    margin-top: 6px;
}

.innerFormContent {
  display: flex;
  gap: 24px;
}

.innerFormContent .formContent {
  width: 50%;
}

.borderNone,
.formArea .innerFormContent:last-child .formContent {
    border: none !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

.summaryContent {
  margin-top: 32px;
}

.summaryLabel {
    font-size: var(--font10--size);
    font-weight: 500;
}

.summaryAnsLabel {
    font-size: var(--font2--size);
    font-weight: 700;
}

.summaryInnerContent {
    padding-bottom: 16px;
    border-bottom: 1px dashed #000000;
    margin-bottom: 18px;
}

@media (max-width: 768px) {
    .manageOrder {
        display: flex;
        flex-direction: column;
    }

    .manageOrder .customContainer:first-child {
        order: 2;
    }

    .manageOrder .customContainer:last-child {
        order: 1;
    }

    .cardContainer {
        grid-template-columns: 100%;
        gap: 0;
    }

    .customContainer {
        margin: 0;
        padding: 0 15px;
    }

    .innerFormContent {
        display: block;
    }

    .innerFormContent .formContent {
        width: 100%;
    }

    .innerFormContent .formContent.borderNone:first-child {
        border-bottom: 1px solid #d5dde9 !important;
        padding-bottom: 24px !important;
    }

    .customContainer .card {
        margin-bottom: 30px;
    }

    .innerContainer {
        flex-direction: column;
    }

    .leftCard .innerContainer,
    .rightCard .innerContainer {
        align-items: flex-start;
    }
}
