.orgTripBookingTab {
  position: sticky;
  position: -webkit-sticky;
  top: 95px;
  z-index: 2;
}

.orgTrDaTabPriceDiv {
  position: relative;
  height: 45px;
  border: 1px solid var(--main--border--color);
  border-radius: 10px 10px 0px 0px;
  border-bottom: none;
  padding: 15px 10%;
  background: linear-gradient(85deg,
      white 14%,
      var(--main--background--color) 85%);
  box-shadow: var(--box--shadow) 0px 6px 16px;
  display: flex;
  flex-direction: column;
}

.orgTrDaTabPrice {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.orgTrDaTabIncu {
  margin: 0;
  font-size: 10px;
}

.orgTrDaTabSlotIn {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #DCDCE9;
  background: var(--main--background--color);
  margin-top: 5px;
  margin-bottom: 30px;
}

.orgTrDaTabDateDis {
  position: absolute;
  top: 127px;
  background: var(--main--background--color);
  padding: 10px;
  border-radius: 10px;
  width: 74%;
  font-size: 14px;
}

/* css for date select to over right it */

.SingleDatePicker {
  border-radius: 8px !important;
  border: 1px solid #DCDCE9;
  background: var(--main--background--color) !important;
  margin-top: 5px !important;
}

.SingleDatePickerInput__withBorder {
  border: none !important;
  background: var(--main--background--color) !important;
  border-radius: 10px;
}

.DateInput {
  background: var(--main--background--color) !important;
}

.DateInput_input {
  border: none !important;
  font-size: 14px;
  line-height: 20px;
  background: var(--main--background--color) !important;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .orgTripBookingTab {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    background-color: white;
    border-top: 1px solid #ddd;
    pointer-events: auto;
  }

  .orgTripBookingTab.collapsed {
    max-height: 76px;
  }

  .orgTripBookingTab.expanded {
    position: relative;
  }
}