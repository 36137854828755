.roomsCard {
  display: flex;
  margin-bottom: 30px;
  flex-direction: row;
}

.roomsCardImg {
  border-radius: 10px 0 0 10px;
  box-shadow: 0px 0px 7px #666;
  width: 190px;
  height: 190px;
}

.roomsCardInfoDiv {
  padding: 10px 15px 10px 20px;
  background: linear-gradient(
    85deg,
    white 15%,
    var(--main--background--color) 60%
  );
  display: inline;
  width: 100%;
  border-top-right-radius: var(--main--border--radius);
  border-bottom-right-radius: var(--main--border--radius);
  border: 1px solid var(--main--border--color);
  border-left: none;
}

.roomsCardNameDiv {
  display: flex;
  justify-content: space-between;
}

.roomsCardPriceDiv {
  margin: 5px 5px 5px 0;
}

.roomsCardName {
  font-weight: 600;
  color: var(--main2--font--color);
  margin: 5px 5px 5px 0;
  font-size: var(--font4--size);
}

.roomsCardNight {
  font-size: var(--font3--size);
  color: var(--main--font--color);
  font-weight: 500;
}

.roomsCardPerson {
  display: block;
  margin-bottom: 10px;
}

.roomsCardSelectDiv {
  display: flex;
  justify-content: space-between;
}
