.orgHeaderHomeDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: var(--main--background--color);
    height: 60px;
    padding: 0 90px;
    position: fixed;
    width: 92%;
    top: 0px;
    z-index: 4;
}

/* .orgHeaderBtn {
  border: none;
  padding: 8px 10px;
  margin: 5px 8px;
  font-size: var(--font--size);
  font-weight: 600;
  background: var(--main--background--color);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--main--font--color);
  text-decoration: none;
  cursor: pointer;
}

.orgHeaderBtn:hover {
  background: rgb(221, 221, 221);
  border-radius: var(--main2--border--radius);
}

.orgHeaderBtnSel {
  border: none;
  border-bottom: 2px solid black;
  padding: 6px 10px;
  margin: 5px;
  font-size: var(--font2--size);
  font-weight: 600;
  background: var(--main--background--color);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  text-decoration: none;
} */

.moreDropBtnSel {
    border: 2px solid var(--main2--font--color);
    color: black;
    border-radius: var(--main2--border--radius);
}

.orgHeaderDownArrow {
    width: 20px;
    height: 20px;
    margin: 0px 0px -3px 0px;
    color: var(--main--font--color);
}

.overlay {
    position: fixed;
    /* Positioning and size */
    top: 0;
    left: 0;
    z-index: 3;
    height: 100%;
    width: 100%;
}

.orgTabsEndDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.orgTabsHeading {
    border: 1px solid #dcdce9;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 11px 18px;
}

.orgTabsHeading.add-btn {
    width: auto;
    padding: 11px 18px;
    gap: 6px;
    font-size: var(--font2--size);
    font-weight: 600;
    color: #0f0f30;
    text-decoration: none;
}

.orgHeaderMenuIcon {
    height: 20px;
    width: 20px;
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.logo {
    height: 56px;
    width: 58px;
}

.menu {
    border: none;
    gap: 8px;
    background: var(--main--background--color);
    font-size: 14px;
    font-weight: 600;
    padding: 0;
}

.divider {
    width: 2px;
    height: 40px;
    background: #dcdce9;
}

/*media queries*/
@media (min-width: 768px) and (max-width: 1024px) {
    .orgHeaderHomeDiv {
        padding: 0 40px;
    }
}

@media (max-width: 768px) {
    .orgHeaderHomeDiv {
        padding: 0 20px;
        min-width: 95%;
    }

    .orgTabsHeading.add-btn {
        display: none;
    }
}