.rangeBar {
    height: 5px;
    width: 100%;
    background-color: #EAF1F7;
    border-radius: 20px;
    margin-top: 30px;
}

.rangeThumb {
    height: 10px;
    width: 10px;
    border: 3px solid var(--primary-color);
    border-radius: 50%;
    background-color: #FFFFFF;
    outline: none;
}

.rangeIndicator {
    position: absolute;
    height: 4px;
    background-color: var(--primary-color);
    top: 0;
}

.rangeValue {
    position: absolute;
    top: -24px;
    color: #656C76;
    font-size: 12px;
}