.sectionWhyUs {
    display: grid;
    grid-template-columns: 49% 49%;
    margin: 60px 0;
    padding: 20px 0;
    position: relative;
    align-items: center;
}

.sectionWhyUs .bannerImg {
    width: 90%;
}

.innerGrid {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 20px;
}

.whyUsGrid {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px;
    border-radius: 8px;
}

.readMoreBtn {
    background: linear-gradient(97.33deg, #006AFF -1.25%, #6FA6F2 106.84%);
    padding: 14px 30px;
    border-radius: 8px;
    box-shadow: 0px 6px 6px 0px #006AFF26;
    color: #FFFFFF;
    border: none;
    margin-top: 24px;
    outline: 1px dashed #006AFF;
    outline-offset: 5px;
    position: relative;
    cursor: pointer;
}

.readMoreBtn:hover {
    background: var(--primary-color-dark);
}

.bridgeBackground {
    position: absolute;
    right: -12%;
    top: 0;
    width: 20%;
}

@media (max-width: 767px) {

    .sectionWhyUs,
    .innerGrid {
        grid-template-columns: 99%;
    }

    .sectionWhyUs .bannerImg {
        width: 50%;
        margin-bottom: 20px;
    }

    .bridgeBackground {
        display: none;
    }

    .readMoreBtn {
        width: 100%;
    }
}