.privacy h2 {
    font-size: 16px;
    margin-top: 10px;
}

.privacy p {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 12px;
    margin-top: 8px;
    text-align: justify;
}

.privacy ul {
    padding-inline-start: 16px;
    margin-bottom: 16px;
    font-size: 14px;
}

.privacy table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.privacy table th,
.privacy table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.privacy table th {
    background-color: #f2f2f2;
}

.customTitle {
    margin-bottom: 20px;
}

.customTitle .titleBannerImage {
    display: none;
}