.orgAddBoBack {
    position: fixed; /* Positioning and size */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(128, 128, 128, 0.7);
    z-index: 5;
}

.orgAddBoDiv {
    position: absolute;
    top: 10%;
    left: 10%;
    text-align: center;
    background: white;
    border-radius: var(--main--border--radius);
    padding: 20px 0px;
    width: 76%;
    height: 70%;
    overflow: auto;
}

.orgAddBoH1 {
    margin: 0px 0px 20px 0px;
    font-size: 20px;
    font-weight: 700;
    color: black;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
}

.orgAddBoCancel {
    position: absolute;
    top: 3%;
    right: 1%;
    border: none;
    background: none;
    cursor: pointer;
}

.orgAddBoCloseIcon {
    width: 28px;
    height: 28px;
    background: white;
    padding: 2px;
}

.orgAddBoCloseIcon:hover {
    background: var(--main2--background--color);
    border-radius: 50%;
}

.orgAddBoCloseIcon:active {
    background: white;
    border-radius: 50%;
}

.orgAddBoSubDiv {
    padding: 0px 10px;
}

.orgAddBoUserDiv {
    padding: 0px 10px 5px 40px;
}

.orgAddBoSubmit {
    height: 35px;
    width: 25%;
    margin: 0px 25px 10px 25px;
    padding: 2px 5px 2px 5px;
    background: linear-gradient(97.33deg, #006aff -1.25%, #6fa6f2 106.84%);
    border-radius: var(--main--border--radius);
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
}
