.supLogin {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
}

.supLoginLogoDiv {
    height: 75px;
    text-align: center;
    border-bottom: 1px solid var(--main--border--color);
    padding: 20px 20px;
}

.supLoginDiv {
    grid-column: 1;
    text-align: center;
    border-radius: var(--main--border--radius);
    width: 65%;
    margin: auto;
    margin-top: 12%;
}

.supLoginH1 {
    margin: 0px 0px 20px 0px;
    font-size: 32px;
    font-weight: 700;
    color: black;
    padding-bottom: 10px;
}

.supLoginInputDiv {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

.supLoginInput {
    margin: 0px 25px 15px 25px;
    height: 30px;
    border-radius: var(--main--border--radius);
    border: 2px solid var(--main--border--color);
    padding: 2px 5px 2px 10px;
}

.supLogin .supLoginInputDiv input {
    margin-bottom: 12px;
    height: 36px;
    border: 1px solid var(--main--border--color);
    border-radius: 6px;
    padding: 2px 5px 2px 10px;
    outline: none;
}

.supLogin .supLoginInputDiv .supLoginLinkDiv a {
    font-size: var(--font3--size);
    padding: 1px 5px 15px 5px;
    margin-top: 0px;
    text-decoration: none;
    cursor: pointer;
}

.supInputDiv {
    display: flex;
    flex-direction: column;
}

.supInputDiv p {
    color: var(--table-body-font);
    text-align: center;
    font-size: var(--font--size);
    margin-bottom: 20px;
}

.supInputDiv label {
    text-align: left;
    margin-bottom: 6px;
    font-size: var(--font--size);
    font-weight: 500;
    color: #3b3f46;
}

.supLoginError {
    color: red;
    margin: -12px 0px 20px 32px;
    font-size: 12px;
    text-align: left;
}

.supLoginBtn {
    height: 35px;
    width: 88%;
    margin: 0px 25px 10px 25px;
    padding: 2px 5px 2px 5px;
    background: var(--primary-color);
    border-radius: var(--main--border--radius);
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.supLoginBtn:hover {
    background: var(--primary-color-dark);
}

.supLoginBtn:active {
    background: var(--primary-color-light);
}

.supLoginDiv svg {
    width: 30%;
    height: auto;
}

.supLoginInfoDiv {
    grid-column: 2;
    /* padding: 50px 20px 20px 15%; */
    background: #f7693d;
    height: 100vh;
    /* margin-top: -18%; */
    text-align: center;
    align-content: center;
}

.supLoginInfoDiv svg {
    width: 75%;
    height: auto;
}

.supLoginInfoDiv h2 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: white;
}

.supContInfDiv {
    display: flex;
    align-content: center;
    text-align: center;
    width: 50%;
    margin: auto;
}

.supLoginInfoDiv .contact-details {
    background-color: initial;
    padding: 0;
    border-radius: 0;
}

.supLoginInfoDiv .contact-details .details-item .icon-con svg {
    fill: #ffffff;
}

.supLoginInfoDiv .contact-details .details-item .icon-con {
    border-color: #ffffff;
}

.supLoginInfoDiv .contact-details .details-item a,
.supLoginInfoDiv .contact-details .details-item span {
    color: #ffffff;
}

.supLoginInfoDiv .details-item a {
    text-decoration: none;
    font-size: 20px;
    margin-right: 20px;
    color: white;
}

@media (max-width: 991px) {
    .supLogin {
        grid-template-columns: 100%;
    }

    .supLoginDiv {
        width: 90%;
    }

    .supLoginInfoDiv {
        grid-column: 1;
    }

    .supLoginInfoDiv {
        border-top: 1px solid var(--main--border--color);
        margin-top: 20px;
    }

    .contact-details {
        width: 90%;
        flex-direction: column;
    }

    .supLogin {
        justify-items: flex-start;
    }

    .supLoginInfoDiv {
        width: 100%;
    }
}
