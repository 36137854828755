.tripDetailsDiv {
  position: relative;
}

.mobSlider {
  display: none;
}

.tripDetailsImages {
  display: flex;
  flex-direction: row;
}

.tripDetailsMainImage {
  width: 70%;
  margin-right: 5px;
  height: 400px;
}

.tripDetailsMainImg {
  width: 100%;
  height: inherit;
  border-radius: 10px 0px 0px 10px;
}

.tripDetailsOtherImagesFirst {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 400px;
  margin-right: 5px;
}

.tripDetailsOtherImages {
  display: flex;
  flex-direction: column;
  width: 25%;
  height: 400px;
}

.tripDetailsOtherImg1 {
  margin-bottom: 5px;
  height: 48.6%;
}

.tripDetailsOtherImg2 {
  height: 50%;
}

.tripDetailsOtherImg3 {
  margin-bottom: 5px;
  height: 48.6%;
  border-radius: 0px 10px 0px 0px;
}

.tripDetailsOtherImg4 {
  height: 50%;
  border-radius: 0px 0px 10px 0px;
}

.detailsImageDisplayMoreButton {
  position: absolute;
  right: 4%;
  bottom: 4%;
  padding: 14px;
  background: white;
  border-radius: 6px;
  border: none;
  cursor: pointer;
}

.detailsImageDisplayMoreButton:hover {
  background: azure;
}

.detailsImageDisplayMoreButton:active {
  background: grey;
}

@media (max-width: 991px) {

  .tripDetailsMainImage,
  .tripDetailsOtherImagesFirst,
  .tripDetailsOtherImages {
    height: 300px;
  }
}

@media (max-width: 768px) {

  .tripDetailsMainImage,
  .tripDetailsOtherImagesFirst,
  .tripDetailsOtherImages {
    height: 200px;
  }

  .tripDetailsDiv {
    display: none;
  }

  .mobSlider {
    display: flex;
  }
}