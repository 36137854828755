.dropdownSubCon,
.dropdownFieldRow {
    display: flex;
}

.dropdownSubCon {
    flex-direction: column;
}

.dropdownFieldRow {
    justify-content: space-around;
    align-items: flex-end;
    margin-top: 8px;
}

.btnStyle {
    border: none;
    padding: 8px 6px;
    color: #FFFFFF;
    font-size: var(--font3--size);
    border-radius: 10px;
    cursor: pointer;
}

.removeBtn {
    background-color: var(--orange-title);
    height: 36px;
}

.addBtn {
    background-color: var(--primary-color-light);
    margin-top: 20px;
}

.supportSelect {
    width: 100%;
}

.dropdownSubCon input,
.supportSelect {
    padding: 10px;
    border-radius: 10px;
    border: 2px solid var(--main2--background--color);
    margin-top: 5px;
}