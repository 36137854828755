.reviewBoInfoDiv {
  grid-column: 1/4;
  background: var(--main--background--color);
  margin-bottom: 10px;
  border-radius: var(--main--border--radius);
  margin-right: 20px;
}

.reviewBoInfoPackName {
  margin: 5px 0;
  width: 70%;
  display: -webkit-box;
  /*To display ... after certian lines */
  line-clamp: 1;
  /* Number of lines to show */
  -webkit-line-clamp: 1;
  /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* to hide extra lines */
}

.reviewBoInfoDetail {
  background: var(--main--background--color);
  padding: 20px;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid var(--main--border--color);
}

.reviewBoInfoTextDiv {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.reviewBoInfoSpace {
  height: 3px;
  width: 3px;
  display: inline-block;
  background: var(--main--font--color);
  border-radius: 50%;
}

.reviewBoInfotext {
  font-size: var(--font--size);
  color: var(--main--font--color);
}

.reviewBoInfoUserDiv {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  padding: 18px;
  background: white;
  border: 1px solid var(--main--border--color);
  border-radius: var(--main--border--radius);
  box-shadow: var(--box--shadow) 0px 6px 16px;
  margin: 25px;
}

.reviewBoInfoNameAge {
  display: flex;
  flex-direction: row;
}

.reviewBoInfoName {
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 0 10px 5px 10px;
}

.reviewBoInfoLabel {
  font-size: 12px;
  padding: 0px 0px 0px 5px;
  color: var(--main--font--color);
  margin-top: 12px;
  text-align: left;
}

.reviewBoInfoInput {
  padding: 10px;
  border-radius: 10px;
  border: none;
  background: var(--main--background--color);
  margin-top: 5px;
}

.reviewBoInfoBtnDivLog {
  display: flex;
  margin: 20px 0px 0px 20px;
}

.reviewBoInfoBtnGueDiv {
  display: flex;
  margin: 0px 0px 0px 10px;
}

.reviewBoInfoNoteP {
  margin: 10px 120px 10px 20px;
}

.reviewBoInfoLoginBtn {
  height: 40px;
  width: 17%;
  /* margin: 20px 0px 0px 20px; */
  padding: 2px 5px 2px 5px;
  background: linear-gradient(87deg,
      var(--main3--font--color) 30%,
      var(--linear--second--color) 80%);
  border-radius: var(--main--border--radius);
  border: none;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.reviewBoInfoGuestBtn {
  padding: 5px 20px;
  border-radius: var(--main--border--radius);
  border: 1px solid var(--main--border--color);
  color: var(--main--font--color);
  font-weight: 600;
  cursor: pointer;
  background: linear-gradient(85deg,
      white 0%,
      var(--main--background--color) 30%);
  margin: 0px 10px;
  height: 40px;
  width: 150px;
}

@media (max-width: 991px) {
  .bookingReviewDetails {
    display: block;
  }

  .reviewBoInfoDiv,
  .bookingReviewPriceDiv {
    width: 100%;
    padding-bottom: 1px;
  }
}

@media (max-width: 768px) {
  .bookingReviewDetails {
    margin: auto 5%;
  }

  .reviewBoInfoNameAge {
    display: flex;
    flex-direction: column;
  }

  .reviewBoInfoName {
    width: 92%;
  }
}