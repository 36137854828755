.tripDetails {
  margin: auto 5%;
}

.org-content .tripDetails {
  margin: auto 0;
}

.tripDetailsHeadingDiv {
  padding: 15px 0 15px 5%;
  position: sticky;
  top: 0px;
  z-index: 3;
  background: white;
}

.tripBriefInfo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.tripBriefInfo span {
  font-size: 14px;
}

.detailsImageDisplayName {
  margin: 0px 5px 2px 0px;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  gap: 8px;
  align-items: center;
}

.shareButton {
  background-color: var(--card--chip);
  border: none;
  border-radius: 8px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  right: 5.2%;
}

.shareButton:hover {
  background-color: var(--chip--color);
}

.shareButton svg {
  width: 20px;
  height: 20px;
}

.detailsImageDisplayName svg {
  cursor: pointer;
}

.tripDetailsSubDetails {
  color: var(--main--font--color);
}

.tripDetailsDot {
  height: 3px;
  width: 3px;
  display: inline-block;
  background: var(--main--font--color);
  border-radius: 50%;
}

.tripDetailsTextBooking {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;
}

.tripDetailsText {
  grid-column: 1 / 4;
}

.tripDetailsBooking {
  grid-column: 4;
}

@media (max-width: 991px) {

  .tripDetailsTextBooking {
    display: block;
  }

  .tripDetailsTabs,
  .tripDetailsInfoDiv {
    margin-right: 0 !important;
  }

  .tripDetailsText {
    margin-bottom: 20px;
  }

  .shareButton {
    display: none;
  }
}

.loading-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.dot-container {
  display: inline-flex;
  gap: 3px;
  margin-left: 10px;
  position: relative;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: white;
  animation: dotAnimation 1s infinite ease-in-out;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dotAnimation {

  0%,
  20% {
    transform: translateY(0);
    opacity: 0.6;
  }

  50% {
    transform: translateY(-6px);
    opacity: 1;
  }

  100%,
  80% {
    transform: translateY(0);
    opacity: 0.6;
  }
}

.share-button {
  background-color: #28a745;
  /* You can choose any color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}

.share-button:hover {
  background-color: #218838;
  /* Darken on hover */
}

.dot-container {
  display: inline-flex;
  gap: 3px;
  margin-left: 10px;
  position: relative;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: white;
  animation: dotAnimation 1s infinite ease-in-out;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dotAnimation {

  0%,
  20% {
    transform: translateY(0);
    opacity: 0.6;
  }

  50% {
    transform: translateY(-6px);
    opacity: 1;
  }

  100%,
  80% {
    transform: translateY(0);
    opacity: 0.6;
  }
}

.share-button {
  background-color: #1e90ff;
  /* Button color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}

.share-button:hover {
  background-color: #187bcd;
  /* Darken on hover */
}

.dot-container {
  display: inline-flex;
  gap: 6px;
  margin-left: 10px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  animation: pulse 1s infinite ease-in-out;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }

  50% {
    transform: scale(1.5);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0.7;
  }
}

.share-button {
  background-color: #ff6347;
  /* Tomato color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.share-button:hover {
  background-color: #d9534f;
  /* Darken on hover */
}

.dot-container {
  position: absolute;
  display: inline-flex;
  gap: 5px;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  animation: splashAnimation 1s infinite;
  opacity: 0;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes splashAnimation {
  0% {
    transform: scale(0.5);
    opacity: 1;
    top: 0;
    left: 0;
  }

  50% {
    transform: scale(1.5);
    opacity: 0.8;
    top: -10px;
    left: 10px;
  }

  100% {
    transform: scale(2);
    opacity: 0;
    top: -20px;
    left: 20px;
  }
}

.share-button {
  background-color: #28a745;
  /* Green color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.share-button:hover {
  background-color: #218838;
  /* Darken on hover */
}

.dot-container {
  position: relative;
  width: 0;
  height: 0;
  display: inline-flex;
  gap: 5px;
  margin-left: 10px;
}

.dot {
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  animation: splash 1s ease-in-out infinite;
}

.dot:nth-child(1) {
  animation-delay: 0s;
  top: 0;
  left: 0;
}

.dot:nth-child(2) {
  animation-delay: 0.1s;
  top: 0;
  right: 0;
}

.dot:nth-child(3) {
  animation-delay: 0.2s;
  bottom: 0;
  left: 0;
}

@keyframes splash {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }

  50% {
    transform: scale(1.5);
    opacity: 0.7;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.share-button {
  background-color: #007bff;
  /* Blue color */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.share-button:hover {
  background-color: #0056b3;
  /* Darken on hover */
}

.ripple-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
}

.ripple {
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid white;
  border-radius: 50%;
  opacity: 0;
  animation: rippleEffect 1.5s infinite;
}

.ripple:nth-child(1) {
  animation-delay: 0s;
}

.ripple:nth-child(2) {
  animation-delay: 0.3s;
}

.ripple:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes rippleEffect {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }

  70% {
    transform: scale(3);
    opacity: 0.4;
  }

  100% {
    transform: scale(4);
    opacity: 0;
  }
}