.blogDetailsBody {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.blogDetilasContent {
  grid-column: 1/4;
  display: grid;
  padding: 20px 5%;
}


.blogDetailsRelatedDiv {
  grid-column: 4;
}

.blogDetailsHeader {
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 100px 0;
}

.blogPostContent h2 {
  font-size: 20px;
  font-weight: 600;
}

.blogPostContent p {
  font-size: 16px;
  margin-bottom: 8px;
}

.blogPostContent img {
  width: 60%;
  min-height: 180px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.blogAuthorInfo {
  display: flex;
  align-items: center;
  padding: 20px;
  border-top: 1px solid #ddd;
  margin-top: 20px;
}

.authorImage {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-right: 20px;
}

.relatedPosts {
  grid-column: 1/3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
}

.blogComments {
  margin-top: 10px;
  border-top: 1px solid #ddd;
}

.blogComments h3,
.commentForm {
  margin-top: 20px;
}

.commentForm textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
}

.commentForm button {
  background: linear-gradient(97.33deg, #006AFF -1.25%, #6FA6F2 106.84%);
  box-shadow: 0px 6px 6px 0px #006AFF26;
  border: none;
  border-radius: 8px;
  padding: 14px 30px;
  color: #FFFFFF;
  font-weight: 600;
  cursor: pointer;
}

@media (max-width: 991px) {
  .relatedPosts {
    grid-template-columns: repeat(1, 1fr);
  }

  .blogPostContent img {
    width: 100%;
  }
}