.supHeaderHomeDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: var(--main--background--color);
    height: 60px;
    padding: 0 90px;
    position: fixed;
    width: 92%;
    top: 0px;
    z-index: 4;
}

/* .supTabsEndDiv {
  display: flex;
  flex-direction: row;
}

.supTabsHeading2 {
  margin: 5px;
}

.supLoginHeaderPop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(128, 128, 128, 0.5);
  z-index: 2;
} */

.supHeaderDownArrow {
    width: 20px;
    height: 20px;
    margin: 0px 0px -3px 0px;
    color: var(--main--font--color);
}

.overlay {
    position: fixed;
    /* Positioning and size */
    top: 0;
    left: 0;
    z-index: 3;
    height: 100%;
    width: 100%;
}

.supTabsEndDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.supTabsHeading {
    border: 1px solid #dcdce9;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 11px 18px;
}

.supTabsHeading.add-btn {
    width: auto;
    padding: 11px 18px;
    gap: 6px;
    font-size: var(--font2--size);
    font-weight: 600;
    color: #0f0f30;
    text-decoration: none;
}

.supHeaderMenuIcon {
    height: 20px;
    width: 20px;
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.logo {
    height: 56px;
    width: 58px;
}

.menu {
    border: none;
    gap: 8px;
    background: var(--main--background--color);
    font-size: 14px;
    font-weight: 600;
    padding: 0;
}

.divider {
    width: 2px;
    height: 40px;
    background: #dcdce9;
}

/*media queries*/
@media (min-width: 768px) and (max-width: 1024px) {
    .supHeaderHomeDiv {
        padding: 0 40px;
    }
}

@media (max-width: 768px) {
    .supHeaderHomeDiv {
        padding: 0 20px;
        min-width: 95%;
    }
}
