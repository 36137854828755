.featuresSection {
    padding-top: 80px;
}

.featureWrapper {
    display: grid;
    grid-template-columns: 24% 48% 24%;
    gap: 20px;
    margin-bottom: 18px;
    margin-top: 20px;
}

.feature {
    position: relative;
}

.featureCount {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: var(--card--chip);
    color: #FFFFFF;
    font-size: var(--font10--size);
    border-radius: 6px;
    padding: 6px 12px;
}

.featureTitle {
    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: var(--font4--size);
    font-weight: 700;
    color: #FFFFFF;
}

.feature img {
    width: 100%;
    max-height: 220px;
}

.featureWrapperRowTwo {
    display: grid;
    grid-template-columns: 24% 23% 23% 24%;
    gap: 20px;
}

.featureBorder::before {
    content: '';
    position: absolute;
    top: 16px;
    left: 16px;
    right: 16px;
    bottom: 22px;
    border: 2px dashed #ffffff;
    border-radius: 15px;
}

.firstTimeDiscount {
    left: 30px;
    right: inherit;
    top: 36px;
}

.firstBookingTitle {
    color: #FFFFFF;
    position: absolute;
    top: 34%;
    left: 36px;
    font-size: var(--font8--size);
    font-weight: 700;
}

.viewDetailsBtn {
    border: none;
    background-color: #FFFFFF;
    padding: 10px 16px;
    border-radius: 8px;
    position: absolute;
    top: 68%;
    left: 36px;
    color: var(--title--color);
    font-size: var(--font10--size);
    font-weight: 600;
    cursor: pointer;
}

.mobDisplay {
    display: none;
}

@media (max-width: 768px) {
    .mobHidden {
        display: none !important;
    }

    .mobDisplay {
        display: block;
    }

    .featuresSection {
        margin-top: 50px !important;
    }
}