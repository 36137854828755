.holidaySearchWrapper {
    margin-top: 12px;
}

.holidaySearchWrapper .relative {
    position: relative;
}

.holidaySearch {
    outline: none;
    border: none;
    padding: 8px;
    width: 100%;
}

.hoilidayrSearchBtn {
    background: linear-gradient(97.33deg, #006AFF -1.25%, #6FA6F2 106.84%);
    box-shadow: 0px 6px 6px 0px #006AFF26;
    border: none;
    border-radius: 8px;
    padding: 14px 30px;
    color: #FFFFFF;
    font-weight: 600;
    cursor: pointer;
}

.hoilidayrSearchBtn:hover {
    background: var(--primary-color-dark);
}

@media (max-width: 768px) {
    .holidaySearchWrapper {
        flex-direction: column;
    }

    .holidaySearchWrapper .alignCenter {
        justify-content: flex-start;
    }

    .holidaySearchWrapper .alignCenter .ani-search-container {
        top: 7px !important;
        width: 100%;
    }

    .holidaySearch,
    .hoilidayrSearchBtn,
    .holidaySearchWrapper .alignCenter,
    .holidaySearchWrapper .relative {
        width: 100%;
    }

    .hoilidayrSearchBtn {
        margin-top: 8px;
    }

    .holidaySearchWrapper .alignCenter {
        border-bottom: 1px solid #D0D1E2;
    }

    .holidaySearch {
        padding: 16px 0;
    }

    .holidaySearchWrapper img {
        margin-right: 10px;
    }
}