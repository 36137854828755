.roomsDateDiv {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px 10px 20px;
  background: linear-gradient(
    85deg,
    white 15%,
    var(--main--background--color) 60%
  );
  border-radius: var(--main--border--radius);
  border: 1px solid var(--main--border--color);
  margin: 10px 0px 20px 0;
  box-shadow: 0px 0px 7px #666;
}

.roomsDateh3 {
  font-size: var(--font4--size);
  font-weight: 700;
  margin: 5px 0 0px 0px;
}

.roomsDateText {
  color: var(--main--font--color);
}

.dateRangeDiv {
  width: 38%;
  z-index: 100;
}
