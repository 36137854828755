.detailsItineraryDiv {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--main--border--color);
  color: var(--footer--color);
}

.detailsItineraryDayDec {
  padding: 5px 4% 20px 4%;
}

.detailsItineraryDayDecSpan {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--footer--color);
  text-align: justify;
}