.reviewBoSumH1 {
  margin-top: 20px;
  font-size: var(--font4--size);
}

.reviewBoSumCondition {
  font-size: 10px;
  margin-top: 0;
}

.reviewBoSumChangeBtn {
  padding: 12px 20px;
  border-radius: var(--main--border--radius);
  border: 1px solid var(--main--border--color);
  color: var(--main--font--color);
  font-weight: 600;
  cursor: pointer;
  background: linear-gradient(85deg,
      white 0%,
      var(--main--background--color) 30%);
  margin-top: 20px;
}

.reviewBoSumConfirm {
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background: var(--primary-color);
  border-radius: var(--main--border--radius);
  border: none;
  color: white;
  font-weight: 600;
  cursor: pointer;
}