/* .orgPack {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
} */

.orgPackHeadSeaDiv {
    display: flex;
    border-bottom: 1px solid var(--main--border--color);
    /* margin: 0px 0px 20px -60px; */
    margin-bottom: 20px;
}

.orgPackH1 {
    font-size: var(--font7--size);
    font-weight: 700;
    padding: 10px 5px;
    margin: 0px 0px 0px 0px;
    color: var(--main--font--color);
}

.orgPackSearchDiv {
    display: flex;
    gap: 8px;
    width: 50%;
}

.orgPackSearch {
    height: 30px;
    width: 100%;
    border-radius: var(--main--border--radius);
    border: 1px solid var(--main--border--color);
    padding: 0px 10px 0px 10px;
    background: linear-gradient(
        85deg,
        white 15%,
        var(--main--background--color) 60%
    );
}

.orgPackSearchBtn {
    height: 30px;
    padding: 0px 10px 0px 10px;
    background: linear-gradient(
        85deg,
        white 15%,
        var(--main--background--color) 60%
    );
    border-radius: var(--main--border--radius);
    border: 1px solid var(--main--border--color);
    font-weight: 600;
    cursor: pointer;
    color: var(--main--font--color);
}

.orgPackSearchBtn:hover {
    border: 2px solid var(--main--border--color);
}

.orgPackSearchBtn:active {
    background: var(--main2--background--color);
}

.orgPackCreateBtn {
    padding: 12px;
    background: var(--primary-color);
    border-radius: var(--main--border--radius);
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.orgPackSelect {
    height: 40px;
    width: 30%;
    margin: 0 0 20px 0;
    font-size: var(--font4--size);
    color: var(--main--font--color);
    border-radius: var(--main2--border--radius);
    border: 1px solid var(--main--border--color);
    padding: 5px;
}

/* .orgPackTripStrap {
  grid-column: 2/7;
  padding: 0px 6% 30px;
} */
