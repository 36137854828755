.preHeader {
  background-color: var(--primary-color);
  color: #FFFFFF;
  font-size: var(--font10--size);
  text-align: center;
  padding: 6px;
}

.headerWrapper {
  padding: 10px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 20;
  background-color: #FFFFFF;
  border: 1px solid #DCDCE9;
}

.headerLogoImg {
  height: 50px;
  width: 120px;
  margin-left: 20px;
}

.headerSelect {
  padding: 14px 20px 14px 14px;
  border-radius: 16px;
  border-color: #DCDCE9;
  background-position-y: 20px;
  background-position-x: 95%;
}

.headerMenuBtn {
  padding: 12px 12px 8px 12px;
  background: none;
  border: 1px solid #DCDCE9;
  border-radius: 8px;
  cursor: pointer;
}

.headerMenuIcon {
  width: 16px;
  height: 16px;
}

.headerBtn {
  font-size: var(--font--size);
  font-weight: 500;
  border: none;
  padding: 8px 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.listYourPropertyBtn {
  background-color: transparent;
  color: var(--title--color);
  border: 1px solid #DCDCE9;
}

.listYourPropertyBtn:hover {
  background-color: #DCDCE9;
}

.myTripsBtn {
  background-color: transparent;
  color: var(--title--color);
  border: 1px solid #DCDCE9;
}

.headerLoginBtn {
  background-color: var(--primary-color);
  color: #FFFFFF;
}

.headerLoginBtn:hover {
  background-color: var(--primary-color-dark);
}

.headerTabsEndDiv {
  display: flex;
  align-items: center;
  gap: 16px;
}

.loginHeaderPop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(128, 128, 128, 0.7);
  z-index: 5;
}

.headerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(128, 128, 128, 0.7);
  z-index: 4;
}

.headerSearchBar {
  margin: 0;
  border: 1px solid #DBE3EE;
  border-radius: 16px;
}

.headerHolidaySearchBar {
  width: 64.1vw;
  margin-top: 0;
  border: 1px solid #DBE3EE;
  border-radius: 16px;
  padding-left: 42px;
}

.headerHolidaySearchBar .hoilidayrSearchBtn,
.headerSearchBar .campSearchBtn {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 5px;
}

.headerSearchBar .campSearchBtn {
  width: 100%;
}

.tabDisplay {
  display: none;
}

.headerSearch {
  position: fixed;
  left: 16px;
  right: 16px;
  top: 20%;
  z-index: 2;
  margin: 0;
  width: 90%;
}

@media (max-width: 991px) {
  .tabHidden {
    display: none;
  }

  .tabDisplay {
    display: block;
  }
}

@media (max-width: 768px) {

  .listYourPropertyBtn,
  .myTripsBtn,
  .headerLoginBtn {
    display: none;
  }

  .headerWrapper {
    padding: 8px 20px;
  }

  .headerLogoImg {
    margin: 0;
  }
}