.viewAll {
    margin: 0 !important;
}

.blogSection {
    padding-top: 60px;
}

.blogCards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
}