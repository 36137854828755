.otpinput-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.otpinput {
    width: 25px;
    padding: 10px;
    border: 1px solid var(--main--border--color);
    text-align: center;
    font-size: 16px;
}

.otpinput:focus {
    border: 1px solid #4c6072;
    outline: none;
}

input[type='number'].no-spinner::-webkit-inner-spin-button,
input[type='number'].no-spinner::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'].no-spinner {
    -moz-appearance: textfield; /* Firefox */
}

.no-spinner {
    -webkit-appearance: none; /* Chrome, Safari, Edge, Opera */
    -moz-appearance: textfield; /* Firefox */
}
