.destination-carousel-container {
    display: flex;
    gap: 20px;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding-top: 20px;
    padding-bottom: 60px;
    width: 100%;
}

.destination-carousel-container::-webkit-scrollbar {
    display: none;
}

.destination-carousel-container>* {
    flex: 0 0 calc(25% - 15px);
    max-width: 25%;
    min-width: calc(25% - 20px);
    margin: 0;
}

.destination-carousel-container img {
    width: 100%;
}

.destination-carousel-container .carousel-item {
    position: relative;
}

.carouselInnerCard {
    position: absolute;
    bottom: 16px;
    border: 2.5px solid #FFFFFF;
    background: #FFFFFF;
    left: 12px;
    right: 12px;
    border-radius: 16px;
    padding: 16px;
}

.carouselInnerCard .innerBorder {
    height: 4px;
    background-color: var(--orange-title);
    width: 30px;
    margin-bottom: 10px;
}

.carouselInnerCard p {
    margin: 0;
    font-weight: 600;
    font-size: var(--font--size);
}

.carouselCardInnerBtn {
    background-color: var(--orange-title);
    border: none;
    min-width: 36px;
    min-height: 36px;
    border-radius: 50%;
    cursor: pointer;
}

.carouselBtnWrapper {
    display: flex;
    gap: 20px;
    position: absolute;
    right: 10%;
    top: 22px;
}

.carouselBtn {
    position: relative;
    width: 36px;
    height: 36px;
    background-color: #C9DDE8;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.carouselBtn::before {
    content: '';
    position: absolute;
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    border: 1.5px dashed #c0c7d6;
    border-radius: 50%;
}

.carouselBtn.active::before {
    border-color: #0095FF;
}