.load-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.load-bar {
  width: 20px;
  height: 20px;
  background: #333;
  margin: 0 5px;
  animation: loadingAnimation 1s infinite ease-in-out;
}

@keyframes loadingAnimation {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(2);
  }
  100% {
    transform: scaleX(1);
  }
}
