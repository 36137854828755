.tripDateContainer {
    padding: 12px 20px;
    border: 1px solid #e3edf0;
    border-radius: 16px;
    min-height: 600px;
}

.orgTripDaUserDe {
    padding: 5px;
    /* border-bottom: 1px solid var(--main--border--color); */
}

.cardLabelDisplay {
    display: flex;
    gap: 4px;
}

.cardLabelDisplay.multiple {
    flex-direction: column;
}

@media (max-width: 991px) {
    .orgTripDateUpDiv {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        justify-content: space-between;
        gap: 8px;
    }

    .orgTripDateOverview {
        margin: 0;
    }
}

@media (max-width: 768px) {
    .tripDateContainer {
        padding: 0;
        border: 0;
    }
}