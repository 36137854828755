.supportAssContainer {
    padding: 12px 20px;
    border: 1px solid #e3edf0;
    border-radius: 16px;
    min-height: 600px;
}

.supportSearchContainer {
    display: flex;
    margin-bottom: 24px;
}

.supportAssSearchDiv {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--main--border--color);
    border-radius: 16px;
    display: flex;
    align-items: center;
}

.supportAssSearchInput {
    width: 70%;
    padding: 10px 10px 10px 40px;
    border: none;
    outline: none;
}

.supportAssSearchInput:focus {
    outline: none;
}

.supportSelect {
    border: none;
    outline: none;
    margin-bottom: 0;
}

.supportSelect:focus {
    outline: none;
}

.supportAssSearchBtn {
    padding: 10px 30px;
    background: var(--primary-color);
    border-radius: var(--main--border--radius);
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.supportAssResultDiv {
    margin: 20px 5%;
}

.supAssdivider {
    width: 1px;
    height: 30px;
    background: #d0d1e2;
}

.supportAssLabel {
    font-weight: 600;
    font-size: 22px;
    color: #14141f;
    margin-bottom: 12px;
}

.ticketTitle {
    font-weight: 600;
    font-size: 14px;
    color: #071529;
    margin-bottom: 6px;
    padding-top: 18px;
}

.ticketContent {
    font-weight: 500;
    font-size: 16px;
    color: #14141f;
    padding-bottom: 18px;
}

.ticketComment {
    width: 100%;
    height: 100px;
    border: 1px solid #dcdce9;
    border-radius: 4px;
    outline: none;
    margin-bottom: 22px;
}

.dialogContent .ticketContent:not(:last-child) {
    border-bottom: 1px solid #dcdce9;
}

.dialogActBtn {
    padding: 14px 52px;
    border: none;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
}

.dialogActBtn.cancel {
    background: linear-gradient(97.33deg, #e5633e -1.25%, #ff9d81 106.84%);
}

.dialogActBtn.submit {
    background: linear-gradient(97.33deg, #006aff -1.25%, #6fa6f2 106.84%);
}
