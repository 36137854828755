.orgLoginLogoDiv {
    height: 75px;
    text-align: center;
    border-bottom: 1px solid var(--main--border--color);
    padding: 20px 20px;
}

.orgLoginDiv {
    grid-column: 1;
    text-align: center;
    border-radius: var(--main--border--radius);
    width: 65%;
    margin: auto;
    margin-top: 12%;
}

.orgLoginInfoDiv {
    grid-column: 2;
    /* padding: 50px 20px 20px 15%; */
    background: #f7693d;
    height: 100vh;
    /* margin-top: -18%; */
    text-align: center;
    align-content: center;
}

.orgLoginCancel {
    position: absolute;
    top: 3%;
    right: 1%;
    border: none;
    background: none;
    cursor: pointer;
}

.orgLoginH1 {
    margin: 0px 0px 20px 0px;
    font-size: 32px;
    font-weight: 700;
    color: black;
    padding-bottom: 10px;
}

.orgLoginInputDiv {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
}

.orgLoginError {
    color: red;
    margin: -10px 0 10px 0;
    font-size: 12px;
    text-align: left;
}

.orgLoginLinkDiv {
    display: flex;
    justify-content: center;
}

.orgLoginForgot {
    font-size: var(--font3--size);
    padding: 1px 5px 15px 5px;
    margin-top: 0px;
    cursor: pointer;
    /* border-bottom: 1px solid black; */
}

.orgLoginForgotP {
    font-size: var(--font3--size);
    padding: 1px 5px 15px 5px;
    margin-top: 0px;
    color: black;
    font-weight: 700;
}

.orgLoginRegister {
    font-size: var(--font3--size);
    padding: 1px 5px 15px 5px;
    margin-top: 0px;
    cursor: pointer;
    text-decoration: none;
    color: black;
    font-weight: 700;
}

.orgLogin {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-items: center;
}

.orgLogin .orgLoginInputDiv input {
    margin-bottom: 12px;
    height: 36px;
    border: 1px solid var(--main--border--color);
    border-radius: 6px;
    padding: 2px 5px 2px 10px;
    outline: none;
}

.orgLogin .orgLoginInputDiv .orgLoginLinkDiv a {
    font-size: var(--font3--size);
    padding: 1px 5px 15px 5px;
    margin-top: 0px;
    text-decoration: none;
    cursor: pointer;
}

.orgLoginBtn {
    background-color: var(--primary-color);
    padding: 14px 12px;
    border-radius: 6px;
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    height: 40px;
}

.orgLoginBtn:hover {
    background: var(--primary-color-dark);
}

.orgLoginBtn:active {
    background: var(--primary-color-light);
}

.orgLoginDiv svg {
    width: 30%;
    height: auto;
}

.orgLoginInfoDiv svg {
    width: 75%;
    height: auto;
}

.orgLoginInfoDiv h2 {
    margin-top: 20px;
    margin-bottom: 10px;
    color: white;
}

.orgContInfDiv {
    display: flex;
    align-content: center;
    text-align: center;
    width: 50%;
    margin: auto;
}

.contact-details.org-login svg {
    width: 60%;
}

.orgLoginInfoDiv .contact-details {
    background-color: initial;
    padding: 0;
    border-radius: 0;
}

.orgLoginInfoDiv .contact-details .details-item .icon-con svg {
    fill: #ffffff;
}

.orgLoginInfoDiv .contact-details .details-item .icon-con {
    border-color: #ffffff;
}

.orgLoginInfoDiv .contact-details .details-item a,
.orgLoginInfoDiv .contact-details .details-item span {
    color: #ffffff;
}

.contact-details.org-login .icon-con {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border: 2px dashed white;
}

.orgLoginInfoDiv .details-item a {
    text-decoration: none;
    font-size: 20px;
    margin-right: 20px;
    color: white;
}

@media (max-width: 991px) {
    .orgLogin {
        grid-template-columns: 100%;
    }

    .orgLoginDiv {
        width: 90%;
    }

    .orgLoginInfoDiv {
        grid-column: 1;
    }

    .orgLoginInfoDiv {
        border-top: 1px solid var(--main--border--color);
        margin-top: 20px;
    }

    .contact-details {
        width: 90%;
        flex-direction: column;
    }

    .orgLogin {
        justify-items: flex-start;
    }

    .orgLoginInfoDiv {
        width: 100%;
    }
}
