.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* To make the website(cards) responsive */
.carousel * {
  flex: 1 0 auto;
}

.carouselImg {
  border-radius: 18px;
  min-height: 180px;
  cursor: pointer;
  width: 100%;
}

.carouselImgHidden {
  display: none;
}

.carouselPrev {
  position: absolute;
  left: 0;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.carouselNext {
  position: absolute;
  right: 0;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.carouselIndicators {
  position: absolute;
  bottom: 10%;
  display: flex;
  background: rgba(40, 36, 36, 0.2);
  padding: 3px;
  border-radius: 20%;
}

.carouselIndicator {
  height: 5px;
  width: 5px;
  background: white;
  border-radius: 100%;
  margin: 2px;
}

.carouselIndicatorTrs {
  height: 5px;
  width: 5px;
  background: gray;
  border-radius: 100%;
  margin: 2px;
}