.org_tripStrap {
    border: 1px solid #d5dde9;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    cursor: pointer;
}

.org_tripStrapImage {
    border-radius: 22px;
    width: 26%;
}

.org_tripStrapInfoDiv {
    padding: 12px 22px;
    display: inline;
    width: 100%;
}

.org_tripStrapDateDetails {
    display: flex;
    align-items: center;
    background-color: #f9f9fc;
    border: 1px solid #d5dde9;
    padding: 12px 18px;
    border-radius: 12px;
    margin: 20px 0;
    width: fit-content;
}

.org_tripStrapDateDetails span {
    color: var(--filter--label);
    font-size: var(--font--size);
    font-weight: 500;
    margin-left: 0 !important;
}

.org_tripStrapDateDetails .divider {
    height: 18px;
    width: 1px;
    background-color: #d5dde9;
    margin: 0 20px;
}

.org_tripStrapInfo {
    width: 70%;
}

.org_tripDivider {
    width: 100%;
    height: 1px;
    background-color: #d5dde9;
}

.org_tripStrapPriceDiv,
.width30 {
    width: 30% !important;
}

.org_amenities {
    display: flex;
    gap: 24px;
}

.org_tripStrapName {
    display: flex;
    gap: 12px;
}

.org_tripStrapName > p {
    font-size: var(--font4--size);
    font-weight: 600;
    overflow: hidden;
    color: var(--filter--label);
    margin: 0;
    margin-bottom: 12px;
}

.org_tripStrapSubDetails {
    margin-right: 10px;
    color: var(--main--font--color);
}

.org_tripStrapDot {
    height: 3px;
    width: 3px;
    display: inline-block;
    background: var(--main--font--color);
    border-radius: 50%;
    margin: 3px 10px 3px 0px;
}

.org_tripCardNumInfo {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: var(--font3--size);
    color: #7b7c90;
    margin-bottom: 4px;
}

.org_tripCardNumInfo span {
    margin: 0;
    font-size: var(--font--size);
    font-weight: 600;
    color: #23262f;
    line-height: 15px;
}

.org_innerFormContent {
    display: flex;
}

.org_tripStrapCity {
    margin-right: 10px;
    color: var(--main--font--color);
}

.org_tripStrapOver {
    color: var(--main--font--color);
    display: -webkit-box;
    /*To display ... after certian lines */
    -webkit-line-clamp: 3;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* to hide extra lines */
    margin: 20px 0px 5px 0px;
}

.org_tripStrapPrice {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 30px;
    font-weight: 700;
}

.org_tripStrapPrice span {
    font-size: var(--font4--size);
    font-weight: 400;
    margin-left: 0 !important;
}

.org_tripStrapInTax {
    margin: 0;
    font-size: 10px;
}

.org_tripStrapContent {
    display: flex;
}

#org_extraStyle {
    padding: 0 !important;
}

.org_holidayDivider {
    margin: 12px 0;
}

.org_locationInfo {
    font-size: var(--font2--size);
    font-weight: 500;
    color: #006aff;
    margin: 0;
}

.org_locationInfo span {
    font-size: var(--font--size);
    font-weight: 400;
}

.org_typeInfo {
    background-color: var(--chip--color);
    padding: 4px 12px;
    font-size: var(--font3--size);
    color: #ffffff;
    border-radius: 4px;
    height: 16px;
}

.org_typeInfo.Easy {
    background-color: var(--primary-color);
}

.org_typeInfo.Moderate {
    background-color: var(--card--chip);
}

.org_typeInfo.active {
    background-color: var(--filling-fast-color);
}

@media (max-width: 991px) {
    .org_tripStrap,
    .org_tripStrapNameDiv {
        flex-direction: column;
    }

    .org_tripStrapNameDiv {
        align-items: flex-start;
    }

    .org_tripStrapInfoDiv {
        padding: 0;
    }

    .org_tripStrapImage {
        width: 100%;
    }

    .org_tripStrapName {
        margin: 28px 0 12px 0;
    }

    .org_tripStrapDateDetails {
        margin: 10px 0 18px 0;
    }

    .org_tripStrapInfo,
    .org_tripStrapPriceDiv {
        width: 100% !important;
    }

    .org_tripStrapPriceDiv {
        margin-top: 18px;
    }

    .org_tripStrapContent {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .org_tripStrapInfo .org_innerFormContent {
        display: flex;
    }

    .org_tripStrapInfo .org_innerFormContent .formContent {
        width: 50% !important;
    }

    #org_extraStyle {
        border-bottom: none !important;
        padding: 0 !important;
        padding-top: 0 !important;
    }

    .org_tripStrapDateDetails .divider {
        display: none;
    }

    .org_tripStrapDateDetails {
        flex-direction: column;
        gap: 12px;
        align-items: flex-start;
    }
}
