.searchReDiv {
    display: grid;
    grid-template-columns: 240px 1fr;
    margin-top: 40px;
    margin-bottom: 40px;
}

.modal-header {
    background-color: #ffffff;
    text-align: right;
    margin-bottom: 8px;
}

.searchResult {
    padding-left: 30px;
}

.searchResultContentArea {
    grid-column: 2/7;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
}

.searchResultTitleArea {
    padding-left: 10px;
}

.searchResultTitleArea p:first-child {
    color: var(--title--color);
    font-size: var(--font6--size);
    font-weight: 700;
    margin: 0;
}

.searchResultTitleArea p:nth-child(2) {
    color: var(--subtitle--color);
    font-size: var(--font4--size);
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 0;
}

.searchResultSpacing {
    margin-bottom: 30px;
}

.sortBy .dropdownlabel {
    margin-right: 20px;
    margin-top: 0;
    color: var(--dropdown--color);
    font-size: var(--font--size);
    font-weight: 500;
}

.pagination {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.pagination-button {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 16px;
    margin: 0 4px;
    cursor: pointer;
    background-color: #fff;
}

.pagination-button.active {
    background-color: #006aff;
    color: #fff;
}

.pagination-button:hover {
    background-color: #f1f1f1;
}

.filterButton {
    border: 1px solid #d5dde9;
    padding: 14px 22px;
    background-color: transparent;
    color: #056dfe;
    width: 100%;
    font-size: var(--font2--size);
    font-weight: 500;
    border-radius: 12px;
    margin-top: 18px;
    cursor: pointer;
}

.filterButton,
.mobileFilter {
    display: none;
}

.loadMoreContianer {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.loadMoreContianer button {
    background: linear-gradient(97.33deg, #006AFF -1.25%, #6FA6F2 106.84%);
    box-shadow: 0px 6px 6px 0px #006AFF26;
    border: none;
    border-radius: 8px;
    padding: 14px 30px;
    color: #FFFFFF;
    font-weight: 600;
    cursor: pointer;
}

.loadMoreContianer button:hover {
    background: var(--primary-color-dark);
}

@media (max-width: 1200px) {
    .searchResultContentArea {
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media (max-width: 991px) {
    .searchReDiv {
        grid-template-columns: 100% 1fr !important;
    }

    .filterButton.mobileFilter {
        display: block !important;
    }

    .searchResultContentArea {
        grid-template-columns: repeat(1, 1fr) !important;
    }

    .bannerCarousel {
        position: sticky !important;
        top: 0;
    }

    .filterButton,
    .desktopFilterVersion,
    .searchResultTitleArea p:nth-child(2) {
        display: none;
    }

    .searchResult {
        background-color: #ffffff;
        position: relative;
        padding: 24px;
        border-radius: 12px;
    }

    .mobileFilter {
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .customContainer {
        padding: 0 15px !important;
    }

    .searchReDiv {
        padding: 0 !important;
    }

    .searchResultSpacing {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px !important;
    }

    .searchResultTitleArea {
        margin-bottom: 12px;
    }

    .searchResultTitleArea,
    .dropdownlabel {
        padding: 0 !important;
    }

    .searchResult .searchResultSpacing {
        position: sticky;
        top: 71px;
        background-color: #FFFFFF;
        border-bottom: 1px solid #d5dde9;
        padding: 10px;
        z-index: 2;
    }

    .filterButton.mobileFilter {
        position: sticky;
        top: 130px;
        background-color: #FFFFFF;
        z-index: 2;
        left: 74vw;
        transition: width 0.3s ease;
    }

    .filterButton.mobileFilter.sticked {
        width: 140px;
    }
}