.orgHeadDropDiv {
    position: absolute;
    top: 54px;
    right: 6%;
    display: flex;
    flex-direction: column;
    padding: 15px 0;
    border-radius: var(--main--border--radius);
    text-align: center;
    background: white;
    border-radius: 7px;
    padding: 5px;
    box-shadow: 0px 3px 2px #aab2bd;
    box-sizing: border-box;
    border: none;
    z-index: 4;
}

.orgHeadDropBtn {
    text-decoration: none;
    padding: 0px 25px 10px 25px;
    color: var(--main--font--color);
    font-size: var(--font--size);
    font-weight: 500;
    border: none;
    background: white;
    margin: 0px 0px;
    height: 35px;
    line-height: 35px;
    cursor: pointer;
}

.orgHeadDropBtn:hover {
    /* background: var(--main2--background--color);
    color: white; */
    background: rgb(221, 221, 221);
    border-radius: var(--main2--border--radius);
}

.orgHeadDropLinkSel {
    background: var(--main2--background--color);
    color: white;
}

/*media queries*/
@media (max-width: 768px) {
    .orgHeadDropDiv .add-btn {
        display: block;
        text-decoration: none;
        padding: 0px 25px 0px 25px;
        color: var(--main--font--color);
        font-size: var(--font--size);
        font-weight: 500;
        border: none;
        background: white;
        margin: 0px 0px;
        height: 35px;
        line-height: 35px;
        cursor: pointer;
    }
}

@media (min-width: 1024px) {
    .orgHeadDropDiv .add-btn {
        display: none;
    }
}
