.nestedTagInput {
    padding: 10px;
    border-radius: 10px;
    border: 2px solid var(--main2--background--color);
    margin-top: 5px;
    width: 70%;
}

.nestedTagOkBtn {
    width: 100px;
    height: 35px;
    margin-left: 8px;
    padding: 2px 5px 2px 5px;
    background: linear-gradient(97.33deg,
            var(--primary-color) -1.25%,
            var(--primary-color-light) 106.84%);
    border-radius: var(--main--border--radius);
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
}