.dropdownDiv {
  display: flex;
  flex-direction: column;
}

.dropdownlabel {
  font-size: 12px;
  padding: 0px 0px 0px 5px;
  color: var(--main--font--color);
  margin-top: 12px;
}

.dropdownSelect {
  padding: 14px;
  border-radius: 8px;
  border: none;
  border: 1px solid #DCDCE9;
  margin-top: 5px;
  color: var(--dropdown--color);
  font-weight: 600;
}
