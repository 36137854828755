.destinationBg {
    background-image: url('../../../assets/home/trvto_destinationBg.png');
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 82px;
}

.destinationSection {
    background-color: #E6F0F5;
    position: relative;
}

@media (max-width: 1200px) {
    .destinationBg {
        background-size: inherit;
        padding: 82px;
    }
}

@media (max-width: 768px) {
    #destinationSection {
        padding-bottom: 50px !important;
    }

    .destinationBg {
        padding: 70px;
    }
}