.aboutus-container {
    text-align: center;
    margin: 30px 0;
}

.aboutus-container .homeTitle {
    font-size: 30px;
}

.aboutus-container .innerGrid .img {
    width: 24px;
    height: 24px;
}

.aboutus-container .innerGrid p {
    font-size: 14px;
}

.aboutus-container .bannerImg {
    width: 50%;
}

.aboutus-container .description {
    letter-spacing: 1px;
    line-height: 18px;
    text-align: justify;
    margin-top: 20px;
}

.aboutus-container b {
    font-size: 22px;
}

.aboutus-container ul {
    padding-inline-start: 0;
    margin: 20px 0;
    list-style: none;
}

.aboutus-container ul li::before {
    content: attr(data-icon);
    font-size: 1.25em;
}

.aboutus-container ul li {
    text-align: justify;
    margin-bottom: 12px;
}

.aboutus-container ul li span {
    font-weight: 700;
}

.aboutus-container .joinus-description {
    padding: 18px;
    border-radius: 8px;
    border: 2px solid var(--main--border--color);
}

.aboutus-container .joinus-description p {
    font-weight: 700;
    font-size: 18px;
    font-style: italic;
}