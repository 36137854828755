.charCountDivHid {
  display: none;
}

.charCountDiv {
  color: var(--main--font--color);
  font-size: 11px;
  padding: 0 0 3px 0;
  opacity: 0.8;
  margin: auto 0 0 4px;
  opacity: 0.8;
}

.charCountDivRed {
  color: red;
  font-size: 11px;
  padding: 0 0 3px 0;
  margin: auto 0 0 4px;
  opacity: 0.8;
}
