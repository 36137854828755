.detailsIncludedDiv {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--main--border--color);
  color: var(--footer--color);
  padding-bottom: 15px;
}

.includedpoints {
  padding: 15px;
}

.includedpoints ul {
  padding-inline-start: 0;
}

.incText {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: var(--footer--color);
  padding: 2px 0;
}