.myWishlist {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.myWishlistH1 {
  font-size: var(--font4--size);
  font-weight: 700;
  border-bottom: 1px solid var(--main--border--color);
  padding: 15px 5px;
  margin: 10px 0px 20px -60px;
  color: var(--main--font--color);
}

.myWishlistSelect {
  height: 40px;
  width: 30%;
  margin: 0 0 20px 0;
  font-size: var(--font4--size);
  color: var(--main--font--color);
  border-radius: var(--main2--border--radius);
  border: 1px solid var(--main--border--color);
  padding: 5px;
}

.myWishlistTripStrap {
  grid-column: 2/7;
  padding: 0px 6% 30px;
}

.myWishlistNoData {
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: var(--main--font--color);
}
