.searchFilterModal {
    display: none !important;
}

@media (max-width: 991px) {
    .searchFilterModal {
        display: block !important;
        transform: translate(0, 35%);
        background-color: transparent !important;
    }

    .searchFilterModal .modal-content {
        margin: 0 30px !important;
    }

    .confirm-btn {
        background-color: #d9534f;
        color: white;
        border: none;
        padding: 10px 20px;
        margin-right: 10px;
        cursor: pointer;
        border-radius: 4px;
    }

    .cancel-btn {
        background-color: #5bc0de;
        color: white;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 4px;
    }

    .confirm-btn:hover,
    .cancel-btn:hover {
        opacity: 0.8;
    }

    .filterDiv {
        border: none !important;
        padding: 0 !important;
    }

    .filterOverlay {
        background-color: rgba(0, 0, 0, 0.6);
    }
}