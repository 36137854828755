.blogUploadDiv {
  margin: 20px 8%;
  border: 1px solid gray;
  padding: 1% 2%;
  border-radius: var(--main2--border--radius);
}

.blogUpHead {
  text-align: center;
  margin: 5px 5px 5px 5px;
}

.flex {
  display: flex;
  margin: 20px 0;
}

.blogUploadDiv .btnContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.blogUploadDiv .submitBtn {
  background: linear-gradient(97.33deg, #006AFF -1.25%, #6FA6F2 106.84%);
  box-shadow: 0px 6px 6px 0px #006AFF26;
  border: none;
  border-radius: 8px;
  padding: 14px 30px;
  color: #FFFFFF;
  font-weight: 600;
  cursor: pointer;
}

.blogUploadDiv label {
  width: 30%;
}