@import '~react-dates/lib/css/_datepicker.css';
/* For react tostify */
@import '~react-toastify/dist/ReactToastify.css';

body,
* {
    margin: 0;
    font-family: 'Kumbh Sans', sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: 'YOPQ' 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.loadingBackground {
    position: fixed;
    /* Positioning and size */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /* background: rgba(128, 128, 128, 0.8); */
    background: rgba(253, 252, 252, 0.8);
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loadingSpin {
    position: fixed;
    width: 150px !important;
    height: 50px;
    z-index: 100;
}

.developer {
    display: none;
}

:root {
    --main--border--radius: 10px;
    --main2--border--radius: 5px;
    --main3--border--radius: 8px;
    --main-color: white;
    --navBg: #14141f;
    --main--font--color: #717171;
    --main2--font--color: #222;
    --main3--font--color: #ff385c;
    --linear--second--color: #e00b41;
    --main--background--color: #f7f7f7;
    --main2--background--color: #a3a1a1;
    --main3--background--color: #e9e9e9;
    --title--color: #0f0f30;
    --description--color: #44455f;
    --subtitle--color: #2e2f45;
    --dropdown--color: #040f1e;
    --filter--label: #040423;
    --tab-inactive-color: #757693;
    --primary-color: #006aff;
    --primary-color-light: #6fa6f2;
    --primary-color-dark: #014db8;
    --filling-fast-color: #3eb454;
    --chip--color: #e5633e;
    --card--chip: #f7921e;
    --orange-title: #ff4c1a;
    --footer--color: #14141f;
    --footer--font: #8d99ac;
    --footer--hover--color: #c4c4c4;
    --main--border--color: rgb(221, 221, 221);
    --table-header-font: #011e29;
    --table-body-font: #7d89a4;
    --detail-value: #546c75;
    --box--shadow: rgba(0, 0, 0, 0.12);
    --font--size: 14px;
    --font2--size: 16px;
    --font3--size: 12px;
    --font4--size: 18px;
    --font5--size: 13px;
    --font6--size: 28px;
    --font7--size: 20px;
    --font8--size: 26px;
    --font9--size: 24px;
    --font10--size: 12px;
}

#webpack-dev-server-client-overlay {
    display: none;
}

.blueBtn:hover {
    background: var(--primary-color);
}

.blueBtn:active {
    background: var(--primary-color-dark);
}

.grayBtn:hover {
    background: var(--main--background--color);
}

.grayBtn:active {
    background: var(--main2--background--color);
}

.w-full {
    width: 100% !important;
}

.btn {
    background-color: transparent;
    border: none;
}

.flex {
    display: flex;
}

.alignCenter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.row {
    flex-direction: row !important;
}

input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    display: flex;
    align-content: center;
    justify-content: center;
    border: 1px solid #a7bbcc;
    border-radius: 6px;
    position: relative;
}

input[type='checkbox']:checked {
    background-color: var(--primary-color);
}

input[type='checkbox']::before {
    content: '';
    width: 1.4rem;
    height: 1.4rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0);
    background-color: var(--primary-color);
    transition: transform 0.2s ease-in-out;
    border-radius: 6px;
}

input[type='checkbox']::after {
    content: '';
    width: 0.4rem;
    /* Adjust size of the checkmark */
    height: 0.2rem;
    /* Adjust size of the checkmark */
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%) scale(0);
    border-left: 2px solid #fff;
    /* Color and thickness of the checkmark */
    border-bottom: 2px solid #fff;
    /* Color and thickness of the checkmark */
    transform: translate(-50%, -50%) rotate(-45deg) scale(0);
    /* Rotate to make it look like a checkmark */
    transition: transform 0.2s ease-in-out;
}

input[type='checkbox']:checked::before {
    transform: translate(-50%, -50%) scale(0.9);
}

input[type='checkbox']:checked::after {
    transform: translate(-50%, -50%) rotate(-45deg) scale(1);
}

.customContainer {
    margin: auto;
    padding-left: 10%;
    padding-right: 10%;
}

/*organizers styles*/
.org-main-container {
    display: flex;
    flex-direction: column;
}

.org-content {
    padding: 24px;
    margin-left: 70px;
    margin-top: 70px;
}

@media (max-width: 768px) {
    .org-content {
        margin-left: 0;
    }

    .loadingSpin {
        width: 80px !important;
        height: 80px !important;
        margin: 0;
    }
}

/*select icon*/
select {
    appearance: none;
    background: transparent;
    background-image: url('./assets/icons/trvto_selectIcon.svg');
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 16px;
}

.dropdownSelect {
    background-position-x: 96%;
    background-position-y: 20px;
}

.animate-fade-in .bg-surface-10 {
    font-size: 30px;
}