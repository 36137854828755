.textareaDiv {
  margin: 10px;
  height: 150px;
  display: flex;
}

.textareaLabel {
  font-size: 16px;
  font-weight: 600;
  color: var(--main--font--color);
  margin-right: 20px;
  align-content: center;
  width: 20%;
}

.textareaInputDiv {
  width: 80%;
  display: flex;
}

.textareaInput {
  padding: 10px;
  border-radius: 10px;
  border: 2px solid var(--main2--background--color);
  /* background: var(--main3--background--color); */
  margin-top: 5px;
  width: 95%;
}
