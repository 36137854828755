.orgBookingContainer {
    padding: 12px 20px;
    border: 1px solid #e3edf0;
    border-radius: 16px;
    min-height: 600px;
}

.orgBookingHeading {
    font-size: var(--font7--size);
    padding: 10px 5px;
    color: var(--title--color);
}

.mr-2 {
    margin-right: 16px;
}

.mb-2 {
    margin-bottom: 16px;
}

.orgBookingFilters {
    display: flex;
    align-items: center;
    gap: 8px;
}

.bookingCardLabelDisplay,
.bookingCardLabelDisplay > div {
    display: flex;
    gap: 4px;
}

.orgFilterWrapper {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.whatsAppLink {
    width: 95%;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #dce4e7;
    margin-bottom: 12px;
}

.messageArea {
    width: 100%;
    height: 100px;
    border: 1px solid #dcdce9;
    border-radius: 4px;
    outline: none;
}

@media (max-width: 991px) {
    .bookingTitleArea {
        display: flex;
        flex-wrap: wrap;
    }

    .orgBookingFilters {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

/*media query*/
@media (max-width: 768px) {
    .orgBookingContainer {
        padding: 0;
        border: 0;
    }

    .orgBookingFilters {
        justify-content: space-between;
    }

    .orgBookingFilters select {
        width: 100%;
    }

    .sendMessageBtn {
        display: flex;
        justify-content: flex-end;
        margin-right: 10px;
        margin-bottom: 4px;
    }
}

@media (max-width: 480px) {
    .orgBookingFilters {
        grid-template-columns: repeat(1, 1fr);
    }

    .org-content .alignCenter {
        width: 100%;
    }

    .orgBookingFilters,
    .org-content .alignCenter select,
    .orgTripDateInfo {
        width: 100%;
    }

    .orgTripDateInfo {
        padding: 10px 0;
    }
}
