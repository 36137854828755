.homeSearchWrapper {
  background-color: #FFFFFF;
  padding: 16px 12px;
  width: 70%;
  margin: auto;
  border-radius: 16px;
  box-shadow: 0 1px 5px 9px rgba(0, 0, 0, 0.2);
}

.tabsWrapper {
  border-bottom: 1px solid #D0D1E2;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.homeSearchWrapper .searchCard {
  display: none;
}

@media (max-width: 991px) {
  .homeSearchWrapper .searchCard {
    display: block;
  }

  .homeSearchWrapper .tabsWrapper,
  .homeSearchWrapper .tabContent {
    display: none;
  }
}