.reg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background: white;
  border-radius: var(--main--border--radius);
  padding: 20px;
  width: 30%;
}

.regCancel {
  position: absolute;
  top: 3%;
  right: 1%;
  border: none;
  background: none;
  cursor: pointer;
}

.regCloseIcon {
  width: 28px;
  height: 28px;
  background: white;
  padding: 2px;
}

.regCloseIcon:hover {
  background: var(--main2--background--color);
  border-radius: 50%;
}

.regCloseIcon:active {
  background: white;
  border-radius: 50%;
}

.regH1 {
  margin: 0px 0px 20px 0px;
  font-size: 20px;
  font-weight: 700;
  color: black;
  border-bottom: 1px solid black;
  padding-bottom: 10px;
}

.regInputDiv {
  display: flex;
  flex-direction: column;
}

.regInput {
  margin-bottom: 12px;
  height: 30px;
  border: 1px solid var(--main--border--color);
  border-radius: 6px;
  padding: 2px 5px 2px 10px;
}

.regInput:focus {
  border: 1px solid #4c6072;
  outline: none;
}

.regError {
  color: red;
  margin: -12px 0px 20px 0;
  font-size: 12px;
  text-align: left;
}

.regForgotReg {
  display: flex;
  justify-content: center;
}

.regForgot {
  font-size: var(--font3--size);
  padding: 1px 5px 15px 5px;
  margin-top: 0px;
  cursor: pointer;
  /* border-bottom: 1px solid black; */
}

.regForgotP {
  font-size: var(--font3--size);
  padding: 1px 5px 15px 5px;
  margin-top: 0px;
}

.regInputDiv label {
  text-align: left;
  margin-bottom: 6px;
  font-size: var(--font--size);
  font-weight: 500;
  color: #3b3f46;
}

.regInputDiv p {
  color: var(--table-body-font);
  text-align: left;
  font-size: var(--font--size);
  margin-bottom: 20px;
}

.regInputDiv button:hover {
  background: var(--primary-color-dark);
}

.regBtn {
  background-color: var(--primary-color);
  padding: 8px 12px;
  border-radius: 6px;
  border: none;
  color: white;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
}

.loadingBackgroundCustom {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  z-index: 99;
}

@media (max-width: 768px) {
  .reg {
    width: 80%;
  }
}