.bannerCarousel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin: auto;
}

.bannerCarouselImg {
  width: 100%;
  height: 400px;
  opacity: 1;
  background-position: center;
}

.searchResults .bannerCarouselImg {
  height: 300px;
}

.homePage .bannerCarouselImg {
  height: 65vh;
}

.bannerCarouselImgHidden {
  display: none;
}

.bannerCarouselPrev {
  position: absolute;
  left: 0%;
  background: none;
  border: none;
  background: #333333;
  cursor: pointer;
  color: black;
  opacity: 0.7;
  padding: 5px 12px 3px 5px;
  border-radius: 0px 10px 10px 0px;
}

.bannerCarouselNext {
  position: absolute;
  right: 0%;
  background: none;
  border: none;
  background: #333333;
  cursor: pointer;
  color: black;
  opacity: 0.7;
  padding: 5px 5px 3px 12px;
  border-radius: 10px 0px 0px 10px;
}

.bannerRightArrow {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.bannerCarouselIndicators {
  position: absolute;
  bottom: 6%;
  display: flex;
  background: rgba(40, 36, 36, 0.2);
  padding: 3px;
  border-radius: 20%;
}

.bannerCarouselIndicator {
  height: 5px;
  width: 5px;
  background: white;
  border-radius: 100%;
  margin: 2px;
}

.bannerCarouselIndicatorTrs {
  height: 5px;
  width: 5px;
  background: gray;
  border-radius: 100%;
  margin: 2px;
}

/* Old style */

/* .bannerCarousel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
} */

/* .bannerCarouselImg {
  box-shadow: 0px 0px 7px #666;
  width: 100vw;
  height: 450px;
  opacity: 1;
  background-position: center;
} */

@media (max-width: 768px) {
  .homePage .bannerCarouselImg {
    height: 40vh;
  }
}