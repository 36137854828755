.supportDetailsContainer {
    border: 1px solid #E3EDF0;
    border-radius: 8px;
    padding: 24px;
}

.infoContainer {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    margin-top: 12px;
}

.infoDetail {
    background-color: #EBEFF8;
    border-radius: 8px;
    padding: 12px 18px;
    display: flex;
    gap: 4px;
}

.infoLabel {
    color: var(--table-header-font);
    font-size: var(--font3--size);
    font-weight: 500;
}

.infoValue {
    color: var(--detail-value);
    font-size: var(--font3--size);
}

.supportDivider {
    border: 1px solid #D2DCE9;
    margin: 30px 0;
}

.supportTicketDetails {
    display: grid;
    grid-template-columns: 55% 45%;
    gap: 24px;
}

.supportTicketDetails h4 {
    margin-bottom: 8px;
}

.supportTicketDetails p {
    color: var(--detail-value);
}

.btnContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 8px;
}

.btnSupport {
    display: inline-flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 16px 12px;
    color: #FFFFFF;
    font-size: var(--font3--size);
    border-radius: 10px;
    width: 92%;
    cursor: pointer;
    margin-bottom: 8px;
}

.message {
    background-color: var(--primary-color);
}

.refund {
    background-color: var(--filling-fast-color);
}

.booking {
    background-color: var(--orange-title);
}

.closeTicket {
    background-color: var(--footer--color);
}

.moreDetails {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 20px;
}

.moreDetailsItem {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
}

.moreDetailsItem p {
    font-weight: 600;
    color: var(--footer--color);
}

.iconContainer {
    background-color: #EBF0F8;
    border-radius: 50%;
    min-width: 42px;
    min-height: 42px;
    max-width: 42px;
    max-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 991px) {
    .supportTicketDetails {
        grid-template-columns: 100%;
    }

    .supportTicketDetails>div:first-child {
        order: 1;
    }
}