.blogImage {
  width: 100%;
  height: 200px;
  border-radius: 18px;
  margin-bottom: 14px;
}

.blogCardName {
  margin: 0;
  display: -webkit-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: var(--font4--size);
  font-weight: 700;
  color: var(--title--color);
}

.blogCard {
  margin-bottom: 20px;
  cursor: pointer;
}

.blogCardDis {
  margin: 0;
  margin-top: 8px;
  color: var(--description--color);
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: var(--font2--size);
  font-weight: 400;
}