.blogsRelatedDiv {
  grid-column: 4;
  padding-left: 15px;
}

.blogRelated {
  width: 100%;
  border: 1px solid var(--main--border--color);
  margin-top: 20px;
  min-height: 180px;
}