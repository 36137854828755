.login {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background: white;
    border-radius: var(--main--border--radius);
    padding: 20px;
    width: 30%;
}

.loginCancel {
    position: absolute;
    top: 3%;
    right: 1%;
    border: none;
    background: none;
    cursor: pointer;
}

.loginCloseIcon {
    width: 28px;
    height: 28px;
    background: white;
    padding: 2px;
}

.loginCloseIcon:hover {
    background: var(--main2--background--color);
    border-radius: 50%;
}

.loginCloseIcon:active {
    background: white;
    border-radius: 50%;
}

.loginH1 {
    margin: 0px 0px 20px 0px;
    font-size: 20px;
    font-weight: 700;
    color: black;
    border-bottom: 1px solid black;
    padding-bottom: 10px;
}

.loginInputDiv {
    display: flex;
    flex-direction: column;
}

.loginInputDiv p {
    color: var(--table-body-font);
    text-align: center;
    font-size: var(--font--size);
    margin-bottom: 20px;
}

.loginInputDiv label {
    text-align: left;
    margin-bottom: 6px;
    font-size: var(--font--size);
    font-weight: 500;
    color: #3b3f46;
}

.loginInput {
    margin-bottom: 12px;
    height: 30px;
    border: 1px solid var(--main--border--color);
    padding: 2px 5px 2px 10px;
}

.loginInput:focus {
    border: 1px solid #4c6072;
    outline: none;
}

.loginError {
    color: red;
    margin: -12px 0px 20px 32px;
    font-size: 12px;
    text-align: left;
}

.loginForgotReg {
    display: flex;
    justify-content: center;
}

.loginForgot {
    font-size: var(--font3--size);
    padding: 1px 5px 15px 5px;
    margin-top: 0px;
    cursor: pointer;
}

.loginForgotP {
    font-size: var(--font3--size);
    padding: 1px 5px 15px 5px;
    margin-top: 0px;
}

.loginBtn {
    background-color: var(--primary-color);
    padding: 8px 12px;
    border-radius: 6px;
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
}

.loginBtn:hover {
    background: var(--primary-color-dark);
}

.loginBtn:active {
    background: var(--primary-color-light);
}

@media (max-width: 768px) {
    .login {
        width: 80%;
    }
}
