/* .tripCard {
  margin: 0 24px 24px 0;
} */

.tripCard {
  border: 1px solid #DBE3EE;
  border-radius: 18px;
  padding: 18px;
  /* width: 245px; */
  position: relative;
}

.tripCardInfo {
  cursor: pointer;
}

.tripCardName {
  font-size: var(--font2--size);
  color: var(--filter--label);
  font-weight: 600;
  margin: 8px 0;
}

.tripCardNumInfo {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: var(--font3--size);
  color: #7B7C90;
  margin-bottom: 4px;
}

.tripCardNumInfo span {
  margin: 0;
  font-size: var(--font--size);
  font-weight: 600;
  color: #23262F;
  line-height: 15px;
}

.tripCardDays {
  margin: 5px 0 0;
  font-size: var(--font3--size);
  padding: 2px 5px;
  background: var(--main3--font--color);
  color: white;
}

.tripCardRating {
  margin: 5px 0 0;
  font-size: var(--font3--size);
  color: var(--main--font--color);
}

.tripCardLocation {
  margin: 8px 0;
  font-size: var(--font3--size);
  color: var(--main--font--color);
  display: flex;
  align-items: center;
  gap: 8px;
}

.tripCardPriceDiv {
  display: flex;
  align-items: center;
}

.tripActualPrice {
  margin: 5px 5px 0 0;
  font-size: var(--font2--size);
  text-decoration: line-through;
  color: var(--main--font--color);
  font-weight: 600;
}

.tripPrice {
  margin: 0;
  margin-bottom: 18px;
  font-size: var(--font7--size);
  font-weight: 700;
  color: var(--main2--font--color);
}

.striked {
  text-decoration: line-through;
  margin-right: 8px;
  font-size: 16px;
  font-weight: 400;
}

.viewDetails {
  border: 1px solid #DCDCE9;
  border-radius: 8px;
  background-color: #FFFFFF;
  padding: 14px 0;
  width: 100%;
  font-weight: 600;
  cursor: pointer;
}

.identityTag {
  position: absolute;
  height: 30px;
  padding: 0 10px;
  color: #FFFFFF;
  border-radius: 0 0 8px 8px;
  font-size: 12px;
  font-weight: 600;
  z-index: 1;
  right: 50%;
  transform: translateX(50%);
}

.fillingFast {
  background-color: var(--filling-fast-color)
}

.fewLeft {
  background-color: var(--primary-color)
}

.wishlist {
  position: absolute;
  top: 32px;
  right: 32px;
  background-color: #FFFFFF;
  border: none;
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  line-height: 36px;
}

.wishlist .wishlist-icon {
  width: 16px;
  height: 16px;
}

@keyframes pop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.pop-animation {
  animation: pop 0.3s ease-in-out;
}