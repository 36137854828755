.tab {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: var(--tab-inactive-color);
    font-size: var(--font--size);
    font-weight: 600;
    border-bottom: 3px solid transparent;
}

.tab.active {
    border-bottom: 3px solid var(--primary-color);
    color: var(--title--color);
}

.button-wrapper {
    position: relative;
}

.coming-soon-tag {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: var(--card--chip);
    color: white;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 8px;
    /* text-transform: uppercase; */
}
