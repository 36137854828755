.orgRegisterDiv {
    margin: 0px 8% 20px 8%;
}

.orgRegisterSecDiv {
    border: 1px solid var(--main--border--color);
    border-radius: 10px;
    border-bottom: none;
    padding: 15px 10%;
    box-shadow: var(--box--shadow) 0px 6px 16px;
}

.orgRegisterH1 {
    color: var(--main--font--color);
    font-weight: 600;
    margin-top: 0px;
    font-size: 28px;
    margin-bottom: 30px;
}

.orgRegisterH2 {
    color: var(--main--font--color);
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 15px;
}

.orgRegisterInDiv {
    margin: 10px;
    height: 50px;
    display: flex;
}

.orgRegisterTextDiv {
    margin: 10px;
    height: 150px;
    display: flex;
}

.orgRegisterLabel {
    font-size: 16px;
    font-weight: 600;
    color: var(--main--font--color);
    margin-right: 20px;
    align-content: center;
    width: 20%;
}

.orgRegisterInputDiv {
    width: 80%;
    display: flex;
}

.orgRegisterInput {
    padding: 10px;
    border-radius: 10px;
    border: 2px solid var(--main2--background--color);
    /* background: var(--main3--background--color); */
    margin-top: 5px;
    width: 95%;
}

.orgRegisterSelect {
    padding: 10px;
    border-radius: 10px;
    border: 2px solid var(--main2--background--color);
    /* background: var(--main3--background--color); */
    margin-top: 5px;
    width: 98%;
}

.orgRegisterAddBtn {
    height: 35px;
    width: 16%;
    margin: 0px 0px 0px 0px;
    padding: 2px 5px 2px 5px;
    background: linear-gradient(
        97.33deg,
        var(--primary-color) -1.25%,
        var(--primary-color-light) 106.84%
    );
    border-radius: var(--main--border--radius);
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.orgRegisterDelBtn {
    padding: 9px 20px;
    border-radius: var(--main--border--radius);
    border: 1px solid var(--main--border--color);
    color: var(--main--font--color);
    font-weight: 600;
    cursor: pointer;
    background: linear-gradient(
        85deg,
        white 0%,
        var(--main--background--color) 30%
    );
    margin-top: 10px;
    margin: auto 30px;
}

.orgRegisterTerm {
    text-align: end;
    margin-top: 20px;
    margin-bottom: 10px;
}

.orgRegisterSubmitBtn {
    width: 20%;
    height: 45px;
    margin: 0px 20px 20px 76%;
    padding: 2px 5px 2px 5px;
    background: linear-gradient(
        97.33deg,
        var(--primary-color) -1.25%,
        var(--primary-color-light) 106.84%
    );
    border-radius: var(--main--border--radius);
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.regOTPP {
    margin-top: 10px;
}
