:root {
    --colBorder: #e3eaf3;
    --headBackground: #f3f9fd;
}

.tableContainer {
    max-width: 100%;
    overflow-x: auto;
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 6px;
    overflow: auto;
}

.tableHead {
    background: var(--headBackground);
    height: 35px;
}

th,
td {
    text-align: left;
    font-weight: 600;
    font-size: var(--font--size);
    color: var(--table-header-font);
}

.tableHead th:first-child {
    border-radius: 8px 0 0 8px;
    padding-left: 32px;
}

.tableHead th:last-child {
    border-radius: 0 8px 8px 0;
    padding-right: 32px;
}

.tableBodyRow {
    height: 52px;
    border: 1px solid gray;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
}

.tableBodyRow :hover {
    background: var(--main--background--color);
}

.tableBodyRow td {
    border-top: 1px solid var(--colBorder);
    border-bottom: 1px solid var(--colBorder);
}

.tableBodyRow td:first-child {
    border-left: 1px solid var(--colBorder);
    border-radius: 8px 0 0 8px;
    padding-left: 32px;
}

.tableBodyRow td:last-child {
    border-right: 1px solid var(--colBorder);
    border-radius: 0 8px 8px 0;
    padding-right: 32px;
}

.tableBodyRow td:not(:first-child) {
    font-weight: 500;
    font-size: var(--font3--size);
    color: var(--table-body-font);
}

.responsiveCardContainer {
    display: none;
}

@media (max-width: 768px) {
    .table {
        display: none;
    }

    .responsiveCardContainer {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .DashListOfUpcoming {
        padding: 0;
        border: none;
    }

    .responsiveCard {
        background: #FFFFFF;
        border-radius: 8px;
        border-left: 6px solid var(--primary-color);
        border-top: 1px solid var(--main--border--color);
        border-bottom: 1px solid var(--main--border--color);
        border-right: 1px solid var(--main--border--color);
    }

    .responsiveCard .cardContent p:not(:last-child) {
        border-bottom: 1px dashed #BED2D9;
    }

    .responsiveCard .cardContent p {
        padding: 10px 16px;
        display: flex;
        justify-content: space-between;
    }

    .DashListOfUpcoming select {
        width: 100%;
    }

    .responsiveCard h3 {
        margin: 0 0 0.5rem;
        color: var(--title--color);
    }

    .responsiveCard p {
        margin: 0.25rem 0;
        color: var(--filter--label)
    }
}