.accDropDiv {
  position: absolute;
  top: 50px;
  right: 2%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: var(--main--border--radius);
  background: #FFFFFF;
  border-radius: 20px;
  box-sizing: border-box;
  border: none;
  z-index: 5;
}

.hideWeb {
  display: none !important;
}

.outlinedBtn {
  font-size: var(--font--size);
  background-color: transparent;
  color: var(--title--color);
  border: 1px solid #DCDCE9;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.list {
  display: flex;
  flex-direction: column;
}

.list a {
  padding: 12px 0 14px 0;
  text-decoration: none;
  color: var(--title--color);
  text-align: left;
  border-bottom: 1px solid #DCDCE9;
  font-size: var(--font--size);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list a:last-child {
  border: none;
  padding-bottom: 0;
}

.arrow {
  width: 14px;
  height: 14px;
}

.accDropBtn {
  padding: 0px 8px;
  font-size: var(--font--size);
  font-weight: 500;
  border: none;
  background: white;
  height: 35px;
  cursor: pointer;
  text-align: left;
}

.accDropBtn:hover {
  /* background: var(--main2--background--color);
      color: white; */
  background: rgb(221, 221, 221);
  border-radius: var(--main2--border--radius);
}

.accDropLinkSel {
  background: var(--main2--background--color);
  color: white;
}

@media (max-width: 768px) {
  .hideWeb {
    display: flex !important;
  }
}