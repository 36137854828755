.loading-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.loading-shimmer {
  width: 100%;
  height: 100%;
  /* You have change all 3 colors to similar dark colors to get darker loading */
  /* Increase drakness of the 2nd color to get darker movement  */
  background: linear-gradient(to right, #f6f7f8 4%, #edeef1 25%, #f6f7f8 36%);
  background-size: 1000px 100%;
  /* We can decrrease the seconds to increase  animation speed */
  animation: shimmer 2s infinite linear;
}

@keyframes shimmer {
  100% { 
    /* when you increase this the speed of animation increases, horizontal vertical  */
    background-position: 1000px 0;
  }
}