.seoBg {
    background-image: url('../../../assets/home/trvto_seoBg.png');
    background-repeat: no-repeat;
    margin-top: 80px;
    padding: 40px 0;
    background-color: rgba(216, 231, 239, 0.8);
}

.justifyCenter {
    text-align: center;
}

.seoBtn {
    background-color: transparent;
    border: 1px solid #B1CDDB;
    border-radius: 20px;
    padding: 14px 22px;
    cursor: pointer;
    position: relative;
    margin-right: 24px;
    margin-bottom: 28px;
}

.seoBtn::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -25px;
    width: 24px;
    height: 1px;
    background-color: #B1CDDB;
    transform: translateY(-50%);
    z-index: 0;
}

.seoBtn:last-child::before,
.seoBtn:last-child::after,
.seoBtn:nth-child(5)::before,
.seoBtn:nth-child(5)::after,
.seoBtn:last-child span::after,
.seoBtn:nth-child(5) span::after {
    content: none;
}

.seoBtn::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -3px;
    width: 5px;
    height: 5px;
    background-color: #B1CDDB;
    border-radius: 50%;
    transform: translateY(-50%);
}

.seoBtn span::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -27px;
    width: 5px;
    height: 5px;
    background-color: #B1CDDB;
    border-radius: 50%;
    transform: translateY(-50%);
}