.orgReviewsContainer {
    padding: 12px 20px;
    border: 1px solid #e3edf0;
    border-radius: 16px;
    min-height: 600px;
}

.orgReviewsHeading {
    font-size: var(--font7--size);
    padding: 10px 5px;
    color: var(--title--color);
}

.reviewComments {
    width: 50%;
}

@media (max-width: 768px) {
    .orgReviewsContainer {
        padding: 0;
        border: 0;
    }

    .orgReviews select {
        width: 100%;
    }

    .ratingCon {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .org_tripCardNumInfo {
        margin-bottom: -3px;
    }
}

@media (max-width: 480px) {
    .orgReviewsContainer select {
        width: 100%;
        margin-right: 0;
    }
}