.counterBg {
    background-image: url('../../../assets/home/trvto_counterBg.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.counterWrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding-top: 100px;
    padding-bottom: 40px;
    gap: 20px;
}

.counterWrapper div:not(:last-child) {
    border-right: 1px solid #FFFFFF;
}

.counterBg .counter {
    color: var(--footer--color);
    font-size: var(--font6--size);
    font-weight: 700;
    animation: countUp 3s ease-out forwards;
    margin: 12px 0 4px 0;
}

.counterBg .counterSubhead {
    color: var(--footer--color);
    font-size: var(--font--size);
    font-weight: 600;
}

@keyframes countUp {
    0% {
        content: "0";
    }

    100% {
        content: attr(data-target);
    }
}

@media (max-width: 767px) {
    .counterWrapper {
        grid-template-columns: repeat(2, 1fr);
    }

    #counterWrapper {
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }
}