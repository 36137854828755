.bookingReviewDetails {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: auto 10%;
}

.bookingReviewH1 {
  margin-left: 40px;
  font-size: 26px;
}

.bookingReviewDiv {
  grid-column: 1 / 4;
  margin: 0 4% 20px 0;
}

.bookingReviewPriceDiv {
  grid-column: 4;
}