.campSearchWrapper {
    margin-top: 12px;
    display: grid;
    grid-template-columns: 30% 30% 20% 20%;
    justify-items: center;
    align-items: center;
}

.campSearchWrapper .relative {
    position: relative;
}

.campSearchWrapper .DateRangePickerInput {
    display: flex;
    align-items: center;
}

/* .campSearchBtn {
    width: min-content;
} */

.campSearchWrapper .DateInput_input {
    background: #FFFFFF !important;
    border-radius: 0;
    color: #44455F;
    font-weight: 400;
}

.campSearchWrapper .DateRangePicker {
    width: 100%;
}

.campSearch,
.guestInput {
    outline: none;
    border: none;
    padding: 8px;
    width: 90%;
}

.campSearchWrapper .DateRangePickerInput_arrow {
    z-index: 2;
}

.DateRangePickerInput_calendarIcon {
    padding: 0 !important;
    margin: 0 !important;
}

.campSearchWrapper .DateRangePickerInput_calendarIcon_svg {
    fill: #44455F !important;
}

.guestWrapper {
    margin-left: 40px;
}

.guestInput {
    border: none;
}

@media (max-width: 768px) {
    .campSearchWrapper {
        grid-template-columns: 100%;
    }

    .campSearch,
    .campSearchBtn,
    .campSearchWrapper .alignCenter,
    .campSearchWrapper .DateRangePicker {
        width: 100%;
    }

    .campSearchWrapper .alignCenter,
    .campSearchWrapper .DateRangePicker {
        border-bottom: 1px solid #D0D1E2;
    }

    .campSearchWrapper .DateRangePicker {
        padding: 5px 0;
    }

    .campSearch,
    .guestInput {
        padding: 16px 0;
    }

    .guestWrapper {
        margin: 0;
    }

    .campSearchWrapper img {
        margin-right: 10px;
    }

    .campSearchWrapper .alignCenter {
        justify-content: flex-start;
    }

    .campSearchWrapper .ani-search-container {
        top: 8px !important;
    }
}