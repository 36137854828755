.itineraryDiv {
    margin: 10px;
    height: 150px;
    display: flex;
}

.itineraryInDiv {
    margin: 10px;
    height: 50px;
    display: flex;
}

.itineraryLabel {
    font-size: 16px;
    font-weight: 600;
    color: var(--main--font--color);
    margin-right: 20px;
    align-content: center;
    width: 20%;
}

.itineraryInputDiv {
    width: 80%;
    display: flex;
    align-items: center;
}

.itineraryInput {
    padding: 10px;
    border-radius: 10px;
    border: 2px solid var(--main2--background--color);
    margin-top: 5px;
    width: 95%;
}

.itineraryAddBtn {
    height: 35px;
    width: 16%;
    margin: 0px 0px 0px 0px;
    padding: 2px 5px 2px 5px;
    background: linear-gradient(97.33deg,
            var(--primary-color) -1.25%,
            var(--primary-color-light) 106.84%);
    border-radius: var(--main--border--radius);
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.itineraryDelBtn {
    padding: 9px 20px;
    border-radius: var(--main--border--radius);
    border: 1px solid var(--main--border--color);
    color: var(--main--font--color);
    font-weight: 600;
    cursor: pointer;
    background: linear-gradient(85deg,
            white 0%,
            var(--main--background--color) 30%);
    margin-top: 10px;
    margin: auto 30px;
}