.tripBookingTab {
    position: sticky;
    position: -webkit-sticky;
    top: 30px;
    z-index: 2;
    /* pointer-events: none; */
}

.collapseArrow {
    display: none;
}

.tripBookingPriceDiv {
    position: relative;
    height: 60px;
    background: var(--main3--font--color);
    border: 1px solid var(--main--border--color);
    border-radius: 10px 10px 0px 0px;
    border-bottom: none;
    padding: 15px 10%;
    background: linear-gradient(85deg,
            white 14%,
            var(--main--background--color) 85%);
    box-shadow: var(--box--shadow) 0px 6px 16px;
}

.tripBookingActualPrice {
    padding: 5px;
    background: var(--primary-color);
    margin: 0;
    font-size: 12px;
    text-decoration: line-through;
    width: fit-content;
    color: #ffffff;
}

.tripBookingPrice {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.tripBookingTaxInc {
    margin: 0;
    font-size: 10px;
}

.tripBookingOffer {
    position: absolute;
    top: 0px;
    right: 30px;
    font-size: 12px;
    color: white;
    font-weight: 500;
    padding: 5px;
    background: var(--primary-color);
}

.tripBookingSubTab {
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 10%;
    background: white;
    border: 1px solid var(--main--border--color);
    border-radius: 0 0 var(--main--border--radius) var(--main--border--radius);
    box-shadow: var(--box--shadow) 0px 6px 16px;
}

.tripBookDataInput {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.tripBookDatalabelFirst {
    font-size: 12px;
    padding: 0px 0px 0px 5px;
    color: var(--main--font--color);
}

.tripBookDatalabel2 {
    font-size: 12px;
    padding: 0px 0px 0px 5px;
    color: var(--main--font--color);
    margin-top: 12px;
}

.tripBookInput {
    padding: 10px;
    border-radius: 10px;
    border: none;
    background: var(--main--background--color);
    margin-top: 5px;
}

.tripBookDateDis {
    position: absolute;
    top: 203px;
    background: var(--main--background--color);
    padding: 10px;
    border-radius: 10px;
    width: 74%;
    font-size: 14px;
}

.tripBookOfferDiv {
    display: flex;
    width: 100%;
}

.tripBookOfferIn {
    width: 75%;
    padding: 10px;
    border-radius: 10px 0 0 10px;
    border: none;
    background: var(--main--background--color);
    margin-top: 5px;
    text-transform: uppercase;
    border: 1px solid #dcdce9;
}

.tripBookOfferBtn {
    padding: 6px 10px;
    background: var(--primary-color);
    border-radius: 0 10px 10px 0;
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
    margin-top: 5px;
    width: 25%;
}

.tripBookPriceDiv {
    padding: 0px 5px 0 5px;
    border-top: 1px solid var(--main--border--color);
}

.tripPriceCal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
}

.tripBookPrice {
    font-weight: 600;
    font-size: var(--font--size);
    color: var(--main2--font--color);
    margin-bottom: 0px;
}

.tripBookOfferDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.tripBookOfferText {
    font-weight: 600;
    font-size: var(--font--size);
    color: var(--main2--font--color);
    margin-top: 10px;
    margin-bottom: 0px;
}

.tripBookTotalDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 8px;
}

.tripBookTotalText {
    font-weight: 600;
    font-size: var(--font--size);
    color: var(--main2--font--color);
    margin-top: 10px;
}

.tripBookBtnDiv {
    border-top: 1px solid var(--main--border--color);
    padding: 20px 0 0 0;
}

.tripBookingButton {
    width: 100%;
    padding: 12px;
    background: var(--primary-color);
    border-radius: var(--main--border--radius);
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.bookingDate .SingleDatePicker {
    background-color: transparent !important;
}

.bookingDate .SingleDatePickerInput {
    width: 100%;
    border: 1px solid #dcdce9 !important;
    background-color: transparent !important;
}

.bookingDate .SingleDatePickerInput .DateInput {
    width: 95%;
    background: transparent !important;
}

.bookingDate .SingleDatePickerInput .DateInput input {
    background: transparent !important;
}

@media (max-width: 768px) {
    .collapseArrow {
        display: block;
        position: absolute;
        top: 40px;
        right: 30px;
        cursor: pointer;
        border: none;
        background-color: transparent;
        transform: rotate(180deg);
    }

    .tripBookingTab.expanded .collapseArrow {
        transform: rotate(0deg);
    }

    .tripBookingTab {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        top: auto;
        transition: max-height 0.3s ease-in-out;
        overflow: hidden;
        background-color: white;
        border-top: 1px solid #ddd;
        pointer-events: auto;
    }

    .tripBookingTab.collapsed {
        max-height: 92px;
    }

    .tripBookingTab.expanded {
        position: relative;
    }
}