.blogBody {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 40px;
}

.blogsGrid {
  grid-column: 1/4;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.btnEdit,
.btnDelete {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 8px 12px;
  color: #212121;
  font-size: var(--font3--size);
  border-radius: 10px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .blogBody {
    margin-top: 30px !important;
    grid-template-columns: 100%;
  }

  .blogCarousel {
    position: relative !important;
  }

  .blogCarousel img {
    max-height: 300px;
  }

  .blogsGrid {
    grid-template-columns: repeat(1, 1fr);
  }

  .blogsRelatedDiv {
    display: none;
  }
}