.reviewDiv {
  background: var(--main--background--color);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: var(--main--border--radius);
}

.reviewImgText {
  display: flex;
}

.reviewImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline;
}

.reviewStarsName {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.reviewStars {
  font-size: 14px;
}

.reviewUserName {
  font-size: 14px;
  font-weight: 600;
}

.reviewLocation {
  font-size: 10px;
}

.reviewCommentDiv {
  display: flex;
  flex-direction: column;
  margin: -5px 10px 10px 7%;
}

.reviewComment {
  padding: 3px 0px 0px 0px;
  font-size: 13px;
  color: var(--main--font--color);
}
