.tripStrap {
    border: 1px solid #d5dde9;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    position: relative;
    cursor: pointer;
}

.ratingSection {
    display: flex;
    margin-top: 16px;
    gap: 8px;
    align-items: center;
}

.ratingSection .tripStrapName {
    font-size: 16px;
    margin-top: 5px;
}

.tripStrapImage {
    border-radius: 22px;
    width: 26%;
}

.quickView {
    font-size: var(--font10--size);
    margin-bottom: 6px;
}

.tripStrapInfoDiv {
    padding: 12px 22px;
    display: inline;
    width: 100%;
}

.tripStrapDateDetails {
    display: flex;
    align-items: center;
    background-color: #f9f9fc;
    border: 1px solid #d5dde9;
    padding: 12px 18px;
    border-radius: 12px;
    margin: 20px 0;
    width: fit-content;
}

.tripStrapDateDetails .userCount {
    margin-left: 8px !important;
}

.tripStrapDateDetails span {
    color: var(--filter--label);
    font-size: var(--font--size);
    font-weight: 500;
    margin-left: 8px;
}

.tripStrapDateDetails .divider {
    height: 18px;
    width: 1px;
    background-color: #d5dde9;
    margin: 0 20px;
}

.tripStrapInfo {
    width: 70%;
}

.tripDivider {
    width: 100%;
    height: 1px;
    background-color: #d5dde9;
}

.tripStrapPriceDiv,
.width30 {
    width: 30% !important;
}

.amenities {
    display: flex;
    gap: 24px;
}

.tripStrapName {
    display: flex;
    gap: 12px;
}

.cancelBooking {
    border: 1px solid #d5dde9;
    padding: 12px 22px;
    background-color: transparent;
    color: #e24d4d;
    font-size: var(--font2--size);
    font-weight: 500;
    border-radius: 12px;
    margin-top: 26px;
    cursor: pointer;
    max-width: 168px;
}

.innerStrap {
    display: flex;
    align-items: center;
}

.innerStrapColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0 !important;
}

.innerStrap label {
    margin-left: 16px;
}

.cancelBooking:hover,
.rateButton:hover {
    background-color: rgb(221, 221, 221);
}

.tripStrapName {
    font-size: var(--font4--size);
    font-weight: 600;
    overflow: hidden;
    color: var(--filter--label);
    margin: 0;
    margin-bottom: 6px !important;
}

.tripStrapSubDetails {
    margin-right: 10px;
    color: var(--main--font--color);
}

.tripStrapDot {
    height: 3px;
    width: 3px;
    display: inline-block;
    background: var(--main--font--color);
    border-radius: 50%;
    margin: 3px 10px 3px 0px;
}

.tripStrapCity {
    margin-right: 10px;
    color: var(--main--font--color);
}

.tripStrapOver {
    color: var(--main--font--color);
    display: -webkit-box;
    /*To display ... after certian lines */
    -webkit-line-clamp: 3;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* to hide extra lines */
    margin: 20px 0px 5px 0px;
}

.tripStrapPrice {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 30px;
    font-weight: 700;
}

.tripStrapPrice span {
    font-size: var(--font4--size);
    font-weight: 400;
}

.tripStrapInTax {
    margin: 0;
    font-size: 10px;
}

.tripStrapContent {
    display: flex;
}

#extraStyle {
    padding: 0 !important;
}

.holidayDivider {
    margin: 12px 0;
}

.locationInfo {
    font-size: var(--font2--size);
    font-weight: 500;
    color: #006aff;
    margin: 0;
}

.locationInfo span {
    font-size: var(--font--size);
    font-weight: 400;
}

.typeInfo {
    background-color: var(--chip--color);
    padding: 4px 12px;
    font-size: var(--font3--size);
    color: #ffffff;
    border-radius: 4px;
}

.moreIcons {
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    top: 16px;
    right: 16px;
}

.ratingModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    width: 90%;
    text-align: center;
}

.commentBox {
    width: 90%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: none;
    height: 100px;
}

.modalActions {
    display: flex;
    margin-top: 20px;
    gap: 12px;
    justify-content: flex-end;
    margin-right: 16px;
}

.submitRating,
.closeModal {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submitRating {
    background: #4caf50;
    color: #fff;
}

.closeModal {
    background: #f44336;
    color: #fff;
}

.rateButton {
    border: 1px solid #d5dde9;
    padding: 12px 22px;
    background-color: transparent;
    color: var(--primary-color);
    font-size: var(--font2--size);
    font-weight: 500;
    border-radius: 12px;
    margin-top: 8px;
    width: 72%;
    cursor: pointer;
    max-width: 168px;
}

@media (max-width: 991px) {
    .rateBtnCon {
        display: flex;
        flex-direction: column;
    }

    .tripStrap,
    .tripStrapNameDiv {
        flex-direction: column;
    }

    .moreIcons {
        background-color: var(--main-color);
        border-radius: 20px;
        padding: 4px 8px;
        top: 30px;
        right: 30px;
    }

    .tripStrapNameDiv {
        align-items: flex-start;
    }

    .tripStrapInfoDiv {
        padding: 0;
    }

    .tripStrapImage {
        width: 100%;
    }

    .tripStrapName {
        margin: 28px 0 12px 0;
    }

    .tripStrapDateDetails {
        margin: 10px 0 18px 0;
    }

    .tripStrapInfo,
    .tripStrapPriceDiv {
        width: 100% !important;
    }

    .tripStrapPriceDiv {
        margin-top: 18px;
    }

    .cancelBooking {
        margin-top: 18px;
        width: 100%;
    }

    .tripStrapContent {
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .tripStrapInfo .innerFormContent {
        display: flex;
    }

    .rateBtnCon {
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }

    .rateBtnCon button {
        margin: 10px 0 0 0;
    }

    .typeInfo {
        font-size: 10px;
        padding: 2px 8px;
        line-height: 17px;
    }

    .tripStrapInfo .innerFormContent .formContent {
        width: 50% !important;
    }

    #extraStyle {
        border-bottom: none !important;
        padding: 0 !important;
        padding-top: 0 !important;
    }

    .tripStrapDateDetails {
        /* flex-direction: column; */
        gap: 12px;
        /* align-items: flex-start; */
    }
}