.ayurBookingTab {
  position: sticky;
  position: -webkit-sticky;
  top: 30px;
  z-index: 2;
}

.ayurBookingPriceDiv {
  position: relative;
  height: 60px;
  background: var(--main3--font--color);
  border: 1px solid var(--main--border--color);
  border-radius: 10px 10px 0px 0px;
  border-bottom: none;
  padding: 15px 10%;
  background: linear-gradient(
    85deg,
    white 14%,
    var(--main--background--color) 85%
  );
  box-shadow: var(--box--shadow) 0px 6px 16px;
}

.ayurBookingActualPrice {
  margin: 0;
  font-size: 12px;
  text-decoration: line-through;
}

.ayurBookingPrice {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.ayurBookingTaxInc {
  margin: 0;
  font-size: 10px;
}

.ayurBookingOffer {
  position: absolute;
  top: 0px;
  right: 30px;
  font-size: 12px;
  color: white;
  font-weight: 500;
  padding: 5px;
  background: linear-gradient(
    87deg,
    var(--main3--font--color) 30%,
    var(--linear--second--color) 80%
  );
}

.ayurBookingSubTab {
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 10%;
  background: white;
  border: 1px solid var(--main--border--color);
  border-radius: 0 0 var(--main--border--radius) var(--main--border--radius);
  box-shadow: var(--box--shadow) 0px 6px 16px;
}

.ayurBookDataInput {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.ayurBookDatalabelFirst {
  font-size: 12px;
  padding: 0px 0px 0px 5px;
  color: var(--main--font--color);
}

.ayurBookDatalabel2 {
  font-size: 12px;
  padding: 0px 0px 0px 5px;
  color: var(--main--font--color);
  margin-top: 12px;
}

.ayurBookInput {
  padding: 10px;
  border-radius: 10px;
  border: none;
  background: var(--main--background--color);
  margin-top: 5px;
}

.ayurBookOfferDiv {
  display: flex;
  width: 100%;
}

.ayurBookOfferIn {
  width: 75%;
  padding: 10px;
  border-radius: 10px 0 0 10px;
  border: none;
  background: var(--main--background--color);
  margin-top: 5px;
}

.ayurBookOfferBtn {
  padding: 6px 10px;
  background: linear-gradient(
    87deg,
    var(--main3--font--color) 30%,
    var(--linear--second--color) 80%
  );
  border-radius: 0 10px 10px 0;
  border: none;
  color: white;
  font-weight: 600;
  cursor: pointer;
  margin-top: 5px;
}

.ayurBookPriceDiv {
  padding: 0px 5px 0 5px;
  border-top: 1px solid var(--main--border--color);
}

.ayurBookroomDesDiv {
  display: flex;
  justify-content: space-between;
}

.ayurBookroomDes {
  width: 60%;
  font-weight: 600;
  color: var(--main2--font--color);
  margin-top: 10px;
  margin-bottom: 0px;
}

.ayurBookroomPrice {
  font-weight: 600;
  color: var(--main2--font--color);
  margin-top: 10px;
  margin-bottom: 0px;
}

.ayurBookOfferDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ayurBookOfferText {
  font-weight: 600;
  font-size: var(--font--size);
  color: var(--main2--font--color);
  margin-top: 10px;
  margin-bottom: 0px;
}

.ayurBookTotalDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ayurBookTotalText {
  font-weight: 600;
  font-size: var(--font--size);
  color: var(--main2--font--color);
  margin-top: 10px;
}

.ayurBookBtnDiv {
  border-top: 1px solid var(--main--border--color);
  padding: 20px 0 0 0;
}

.ayurBookingButton {
  width: 100%;
  padding: 12px;
  background: linear-gradient(
    87deg,
    var(--main3--font--color) 30%,
    var(--linear--second--color) 80%
  );
  border-radius: var(--main--border--radius);
  border: none;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
