.dashHeadingDiv {
  display: flex;
  justify-content: space-between;
}

/* .dashBtnDiv {
  align-content: center;
}

.dashOverviewInfo {
  margin-right: 20px;
  padding: 20px;
  background: var(--main--background--color);
  border: 1px soild var(--main--border--color);
  border-radius: var(--main--border--radius);
  margin-bottom: 10px;
  cursor: pointer;
}

.dashselected {
  background: var(--main2--background--color);
  font-weight: 600;
}

.dashboardOverview {
  display: flex;
}

.dashCreateBtn {
  padding: 10px 20px;
  background: linear-gradient(87deg,
      var(--main3--font--color) 30%,
      var(--linear--second--color) 80%);
  border-radius: var(--main--border--radius);
  border: none;
  color: white;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  margin: auto 10px;
}

.dashAnalyticsBtn {
  padding: 12px 20px;
  border-radius: var(--main--border--radius);
  border: 1px solid var(--main--border--color);
  color: var(--main--font--color);
  font-weight: 600;
  cursor: pointer;
  background: linear-gradient(85deg,
      white 0%,
      var(--main--background--color) 30%);
  margin-top: 10px;
  margin: auto 10px;
} */

/*Kpi Card styles*/

.kpi-container {
  display: grid;
  gap: 20px;
  margin-bottom: 30px;
  grid-template-columns: repeat(4, 1fr);
}

.kpi-card {
  background-color: #c2ebda;
  border-radius: 18px;
  padding: 30px;
}

.kpi-card-2 {
  background-color: #ceeef9;
}

.kpi-card-3 {
  background-color: #dde2fa;
}

.kpi-card-4 {
  background-color: #c5f2f2;
}

.kpi-card h1 {
  font-size: var(--font6--size);
  margin: 0;
  margin-bottom: 8px;
}

.kpi-card h4 {
  font-weight: 500;
  margin: 0;
}

/* Table continer Styles*/

.DashListOfUpcoming {
  padding: 12px 20px;
  border: 1px solid #e3edf0;
  border-radius: 16px;
}

.select {
  width: 212px;
  padding: 10px 18px;
  border-radius: 8px;
  border: 1px solid #dce4e7;
  margin-bottom: 12px;
}

/*media query*/
@media (max-width: 768px) {
  .kpi-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .kpi-container {
    grid-template-columns: repeat(1, 1fr);
  }
}