.nav-menu {
    background-color: var(--navBg);
    width: 70px;
    display: flex;
    justify-content: center;
    height: calc(100vh - 60px);
    position: fixed;
    top: 60px;
    transition: width 0.1s ease;
    z-index: 1000;
    overflow-x: hidden;
}

.nav-menu:hover .nav-text .nav-title {
    display: inline-block;
}

.nav-menu:hover {
    width: 270px;
}

.nav-menu.active {
    width: 270px;
}

.nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 6px;
    list-style: none;
    position: relative;
}

.nav-text a {
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    font-size: var(--font2--size);
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 4px;
}

.nav-text.active a {
    background-color: #fff;
    color: #040f1e;
}

.show-menu a {
    padding-left: 12px;
}

.nav-menu-items {
    width: 100%;
    padding: 0;
    margin: 10px 0 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.nav-text .nav-title {
    display: none;
    margin-left: 16px;
}

.nav-text .show {
    margin-left: 16px;
}

.menuExpand {
    display: none;
}

.nav-menu::-webkit-scrollbar {
    width: 8px;
    /* width of the entire scrollbar */
}

.nav-menu::-webkit-scrollbar-track {
    background: #dcdce9;
    /* color of the tracking area */
}

.nav-menu::-webkit-scrollbar-thumb {
    background-color: #9a9a9e;
    /* color of the scroll thumb */
    border-radius: 20px;
}

/*media query*/
@media (max-width: 768px) {
    .nav-menu {
        display: none;
    }

    .nav-menu.active {
        display: flex;
        transition: 0.4s ease-in;
    }

    .show-menu {
        display: none;
    }

    .menuExpand {
        display: block;
        position: fixed;
        padding: 8px 10px 6px 10px;
        top: 60px;
        background-color: var(--navBg);
        border-radius: 0 8px 8px 0;
        cursor: pointer;
        z-index: 4;
    }

    .menuExpandActive {
        left: 270px;
    }
}

@media (max-width: 425px) {
    .nav-menu {
        height: 100vh;
    }
}