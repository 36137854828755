.detailSubHeading {
  margin-bottom: 5px;
  background: var(--primary-color);
  padding: 10px;
  border-radius: 5px;
  width: auto;
  display: inline-block;
  min-width: 100px;
  text-align: center;
}

.detailSubHeadingSpan {
  font-weight: 600;
  font-size: 14px;
  color: white;
}