.discountSection {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 40px;
}

.discountSection img {
    width: 100%;
}

@media (max-width: 767px) {
    .discountSection {
        grid-template-columns: 100%;
        margin-top: 40px;
    }
}