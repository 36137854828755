.orgTripDateUpDiv {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}

.orgTripDateHeadingDiv {
    z-index: 2;
    background: white;
}

.orgTripDateName {
    margin: 0px 5px 2px 0px;
    font-size: 24px;
    font-weight: 600;
}

.cardLayout {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.orgTripDateSubDetails {
    margin-right: 10px;
    color: var(--main--font--color);
}

.orgTripDateDot {
    height: 3px;
    width: 3px;
    display: inline-block;
    background: var(--main--font--color);
    border-radius: 50%;
    margin: 3px 10px 3px 0px;
}

.orgTripDateOverview {
    display: flex;
    /* margin-left: 30px; */
    gap: 20px;
}

.orgTripDateInfo {
    padding: 10px 30px;
    background: #c2ebda;
    border: 1px soild var(--main--border--color);
    border-radius: var(--main--border--radius);
    display: flex;
    flex-direction: column;
    text-align: center;
}

.orgTripDateSpan {
    font-size: 16px;
    font-weight: 600;
}

.orgTripBtnDiv {
    align-content: center;
}

.orgTipBtnDow {
    padding: 12px;
    background: var(--primary-color);
    border-radius: var(--main--border--radius);
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
}

@media (max-width: 768px) {
    .orgTripDateName {
        font-size: 20px;
    }

    .orgTripDateHeadingDiv span {
        font-size: 14px;
    }

    .orgTripDateSubDetails,
    .orgTripDateDot {
        margin-right: 8px;
    }
}

@media (max-width: 568px) {

    .cardLayout,
    .orgTripDateOverview {
        flex-direction: column;
    }

    .cardLayout {
        text-align: center;
    }

    .orgTipBtnDow {
        width: 100%;
    }
}