.title {
    color: var(--title--color);
    font-weight: 700;
    margin: 0;
}

.titleBanner {
    background: rgba(219, 240, 232, 0.6);
    padding: 22px 10%;
    margin-bottom: 50px;
    position: relative;
}

.titleBannerPiece {
    position: absolute;
    right: 20%;
    bottom: 0;
}

@media (max-width: 768px) {
    .titleBanner {
        margin-bottom: 40px;
        padding: 32px 15px;
    }

    .titleBannerPiece {
        display: none;
    }

    .titleBannerImage {
        display: block;
    }

    .title {
        font-size: var(--font7--size);
    }
}
