.reviewSection {
    background-color: #F3F6FB;
    position: relative;
}

.reviewWrapper {
    padding: 80px 0;
}

.reviewBg {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20%;
}

@media (max-width: 1200px) {
    .reviewDetail {
        font-size: 16px;
    }
}