.review-carousel-container {
    display: flex;
    gap: 20px;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding: 20px 0;
    width: 100%;
}

.review-carousel-container>* {
    flex: 0 0 100%;
    max-width: 100%;
    min-width: 100%;
    margin: 0;
}

.review-carousel-container::-webkit-scrollbar {
    display: none;
}

.review-carousel-container img {
    width: 80%;
}

.review-carousel-container .review-carousel-item {
    display: grid;
    grid-template-columns: 40% 60%;
    align-items: center;
    gap: 20px;
    width: 100%;
    overflow-x: hidden;
}

.bottomPiece {
    position: absolute;
    width: 10% !important;
    bottom: 60px;
    right: 28%;
}

.topPiece {
    position: absolute;
    width: 12% !important;
    top: 100px;
    right: 28%;
}

.connectingDots {
    position: absolute;
    width: 65% !important;
    top: 10%;
    left: 32%;
}

.reviewDetail {
    margin: 0;
    font-weight: 500;
    font-size: var(--font7--size);
    color: var(--subtitle--color);
    margin: 18px 0 24px 0;
}

.author {
    color: var(--title--color);
    font-size: var(--font7--size);
    font-weight: 600;
    margin-bottom: 8px;
}

.designation {
    color: var(--primary-color);
    font-size: var(--font2--size);
    font-weight: 500;
}

.reviewCarouselBtnWrapper {
    display: flex;
    gap: 20px;
    position: absolute;
    right: 10%;
    top: 102px;
    z-index: 2;
}

.carouselBtn {
    position: relative;
    width: 36px;
    height: 36px;
    background-color: #C9DDE8;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.carouselBtn.active:hover {
    background: var(--primary-color-dark) !important;
}

.carouselBtn::before {
    content: '';
    position: absolute;
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    border: 1.5px dashed #c0c7d6;
    border-radius: 50%;
}

.carouselBtn.active::before {
    border-color: #0095FF;
}

.carouselBtn.active {
    background: linear-gradient(97.33deg, #006AFF -1.25%, #0095FF 106.84%);
}