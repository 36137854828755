/* Pagination code start */

.paginationContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
}

.numberContainer {
    display: flex;
}

.seleNumber {
    background-color: #040f1e;
    color: white;
    margin: 0px 2px 0 10px;
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    cursor: pointer;
}

.notSeleNumber {
    /* background-color: #b6e8ef; */
    margin: 0px 2px 0 10px;
    padding: 6px 12px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    border: none;
    cursor: pointer;
}

.notSeleNumberP {
    /* background-color: #b6e8ef; */
    margin: 0px 2px 0 10px;
    padding: 7px 15px 6px 14px;
    border-radius: 50%;
    font-family: 'Ruda', sans-serif;
    font-size: 15px;
    border: none;
}

.paginationArrows {
    border: none;
    background-color: white;
    margin-left: 10px;
    cursor: pointer;
}

.paginationArrows:disabled {
    cursor: not-allowed;
}

.peginationNumberDiv {
    display: flex;
}

/* Pagination code ends */