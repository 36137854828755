.termsDisPoints ul {
  padding: 0;
}

.termsDisText {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: var(--footer--color);
  padding: 2px 0;
  line-height: 20px;
}