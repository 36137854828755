.filterDiv {
  grid-column: 1;
  padding: 20px;
  border: 1px solid #DBE3EE;
  border-radius: 12px;
}

.filter {
  font-size: var(--font4-size);
  font-weight: 700;
  margin: 0;
  color: var(--filter--label);
}

.filterTypeHeading {
  padding-bottom: 18px;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: var(--filter--label);
}

.filterSubTypeDiv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.filterSubTypeDiv .filterSubTypeCheck {
  width: 20px;
  height: 20px;
  border: 1px solid #A7BBCC;
  accent-color: var(--primary-color);
}

.filterSubTypeName {
  margin: 0;
  margin-left: 10px;
  color: var(--filter--label);
  font-size: var(--font-size);
}

.anchorButton {
  color: #FF4C1A;
  font-weight: 700;
  font-size: 14px;
  padding: 0;
  cursor: pointer;
}

.accordion-header {
  cursor: pointer;
}

.accordion-icon {
  font-size: var(--font4--size);
}