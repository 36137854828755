.bannerWrapper {
  position: absolute;
  bottom: 15vh;
  width: 100%;
}

.bannerTitle {
  text-align: center;
}

.bannerTitle h1 {
  color: #FFFFFF;
  margin: 8px 0 16px 0;
  font-size: 42px;
}

.pageCount {
  margin: 0;
  font-weight: 600;
  font-size: var(--font2--size);
  color: var(--orange-title);
}

.homeTitle {
  margin: 10px 0;
  color: var(--title--color);
  font-weight: 700;
  font-size: 32px;
}

.description {
  margin: 0 0 20px 0;
  color: var(--description--color);
  font-size: var(--font2--size);
}

@media (max-width: 991px) {
  .bannerCarousel.homePage {
    position: relative !important;
  }

  .searchCard {
    border: 1px solid #D0D1E2;
    background-color: #FFFFFF;
    margin-top: 10px;
    border-radius: 16px;
    padding: 30px 20px;
  }

  .homeSearch {
    width: 100%;
    padding: 14px 10px;
    border-radius: 8px;
    border-color: #DCDCE9;
    background-position-y: 20px;
    background-position-x: 95%;
  }
}