:root {
    --colBorder: #e3eaf3;
    --headBackground: #f3f9fd;
}

.tableBodyRow td.Pending {
    font-weight: 600;
    font-size: 14px;
    color: #da383b;
}

.tableBodyRow td.Approved {
    font-weight: 600;
    font-size: 14px;
    color: #349f32;
}

.billingContainer {
    padding: 12px 20px;
    border: 1px solid #e3edf0;
    border-radius: 16px;
}

.billingContainer .filter .orgBillSearch {
    width: 200px;
    border: 1px solid #dce4e7;
    border-radius: 8px;
    padding: 12px;
}

.billingContainer .filter .filterBtn {
    width: 100px;
    background: #006aff;
    border-radius: 10px;
    padding: 12px 16px;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
    gap: 12px;
}

.accBtnContainer {
    display: flex;
}

.actBtn {
    padding: 8px 16px;
    border-radius: 8px;
    background: #fff;
    cursor: pointer;
    width: 100%;
}

.noWidth {
    width: fit-content;
}

.actBtn.payoutBtn {
    border: 1px solid #006aff;
    color: #006aff;
    margin-right: 10px;
}

.actBtn.billBtn {
    border: 1px solid #e5633e;
    color: #e5633e;
}

@media (max-width: 768px) {
    .accBtnContainer {
        margin: 0.25rem;
    }
}
