.ayurDetails {
  margin: auto 5%;
}

.ayurDetailsHeadingDiv {
  padding: 15px 0 15px 5%;
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 2;
  background: white;
}

.detailsImageDisplayName {
  margin: 0px 5px 2px 0px;
  font-size: 24px;
  font-weight: 600;
}

.ayurDetailsSubDetails {
  margin-right: 10px;
  color: var(--main--font--color);
}

.ayurDetailsDot {
  height: 3px;
  width: 3px;
  display: inline-block;
  background: var(--main--font--color);
  border-radius: 50%;
  margin: 3px 10px 3px 0px;
}

.ayurDetailsTextBooking {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;
}

.ayurDetailsText {
  grid-column: 1 / 4;
}

.ayurDetailsBooking {
  grid-column: 4;
}
