.account-container {
    max-width: 700px;
    margin: 20px auto;
    background: linear-gradient(145deg, #ffffff, #f3f4f6);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 40px;
    transition: all 0.3s ease-in-out;
}

.account-container:hover {
    transform: scale(1.02);
}

.account-header {
    text-align: center;
}

.account-user-pic {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.account-header h2 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 10px;
    color: var(--primary-color);
    font-weight: 600;
}

.account-header p {
    text-align: center;
    font-size: 16px;
    color: var(--description--color);
    margin-bottom: 30px;
}

.account-info {
    background-color: var(--main3--background--color);
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 40px;
    box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.05);
}

.info-item {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #e6e9ef;
}

.info-item:last-child {
    border-bottom: none;
}

.info-item strong {
    color: var(--subtitle--color);
    font-size: 16px;
}

.info-item span {
    color: var(--subtitle--color);
    font-size: 16px;
}

.account-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.account-link {
    color: var(--primary-color);
    text-decoration: none;
    font-size: 18px;
    padding: 12px 24px;
    background-color: var(--main--background--color);
    border-radius: 8px;
    transition: background-color 0.2s ease;
}

.account-link:hover {
    background-color: #cce1ff;
}

.logout-btn {
    background-color: var(--primary-color);
    color: #fff;
    border: none;
    padding: 12px 24px;
    font-size: 18px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.logout-btn:hover {
    background-color: var(--primary-color);
}

@media (max-width: 768px) {
    .account-container {
        margin: 20px;
        padding: 20px;
    }

    .info-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .account-link,
    .logout-btn {
        font-size: 16px;
    }
}