.tagsNoteP {
  color: var(--main--font--color);
}

.tagContainer {
  display: block;
  margin: 20px 0;
  overflow-y: scroll;
  margin-left: 5%;
  margin-right: 5%;
}

.tagContainer::-webkit-scrollbar {
  width: 8px;
}

.tagOutDiv {
  display: inline;
  /* justify-content: flex-start; */
  /* height: 100px; */
  width: 600px;
}

.tagDiv {
  display: inline;
  height: 35px;
  margin: 10px;
  /* border: 1px solid gray; */
  background: #e7e1e1;
  border-radius: 10px;
  padding: 5px;
}

.tagP {
  display: inline;
  height: 30px;
  font-family: Poppins;
  font-style: normal;
}

.tagButton {
  height: 25px;
  font-size: 12px;
  border: none;
  background: #e7e1e1;
  margin-left: 5px;
  margin-top: 15px;
}