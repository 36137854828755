.faqQuestion {
  cursor: pointer;
  font-size: 16px;
  color: var(--footer--color);
  background-color: var(--main--background--color);
  padding: 10px;
  font-weight: 400;
  border-radius: 8px;
}

.faqAnswer {
  font-family: inherit;
  font-style: inherit;
  font-size: 14px;
  padding: 0 10px 10px 10px;
  color: var(--main--font--color);
  font-weight: 400;
  margin-top: 5px;
}