.orgTripDetailsTabs {
    background: var(--main--background--color);
    height: 60px;
    margin-right: 4%;
    border-radius: var(--main--border--radius) var(--main--border--radius) 0 0;
    position: sticky;
    top: 119px;
    z-index: 1;
}

.orgTripDetailsHeadingDiv {
    padding: 15px 0 15px 0;
    position: sticky;
    position: -webkit-sticky;
    top: 40px;
    z-index: 3;
    background: white;
}

.tripDetailsTabs {
    background: var(--main--background--color);
    /* height: 60px; */
    margin-right: 4%;
    border-radius: var(--main--border--radius) var(--main--border--radius) 0 0;
    position: sticky;
    position: -webkit-sticky;
    top: 74px;
    z-index: 1;
}

.tripDetailsTabButton {
    color: var(--main--font--color);
    border: none;
    padding: 20px 25px 20px 25px;
    cursor: pointer;
    text-transform: uppercase;
    /* background: var(--footer--color); */
    font-weight: 600;
    /* border-radius: var(--main--border--radius); */
    font-size: var(--font5--size);
}

.tripDetailsTabButton:hover {
    background: var(--footer--hover--color);
}

.tripDetailsTabButtonHi {
    color: var(--primary-color);
    border: none;
    border-bottom: 4px solid var(--primary-color);
    padding: 20px 31px 20px 30px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
}

.tripDetailsTabButtonHi:hover {
    background: var(--footer--hover--color);
}

.tripDetailsInfoDiv {
    padding: 20px 3%;
    margin-right: 4%;
    border: 1px solid var(--main--background--color);
    border-radius: 0px 0px var(--main--border--radius) var(--main--border--radius);
    box-shadow: var(--box--shadow) 0px 6px 16px;
    font-size: 14px;
    font-weight: 400;
    color: var(--main--font--color);
    /* overflow: auto;
  height: 600px; */
}

.tripHide {
    display: none;
}

@media (max-width: 768px) {

    .tripDetailsTabButtonHi,
    .tripDetailsTabButton {
        padding: 10px;
    }

    .tripDetailsTabs {
        overflow-x: auto;
        display: flex;
    }
}