 #envelope {
     animation: float 2s ease-in-out infinite;
 }

 .contact-container {
     margin: 30px 0;
 }

 .conatct-img {
     width: 80%;
 }

 .contact-container {
     display: grid;
     align-items: center;
     grid-template-columns: 50% 50%;
     gap: 24px;
 }

 .conatact-form {
     margin: 40px 0;
 }

 .conatact-form .cardCon {
     display: flex;
     justify-content: flex-end;
     width: 70%;
     margin: auto;
 }

 .conatact-form .contact-card {
     box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
     border-radius: 8px;
     padding: 20px 40px;
     width: 100%;
 }

 .conatact-form p {
     font-size: var(--font6--size);
     font-weight: 600;
     margin-bottom: 10px;
 }

 .conatact-form .contact-card button {
     background: linear-gradient(97.33deg, #006AFF -1.25%, #6FA6F2 106.84%);
     box-shadow: 0px 6px 6px 0px #006AFF26;
     border: none;
     border-radius: 8px;
     padding: 14px 30px;
     color: #FFFFFF;
     font-weight: 600;
     cursor: pointer;
 }

 .contact-details {
     background-color: var(--primary-color);
     padding: 20px;
     border-radius: 8px;
 }

 .contact-details .details-item {
     font-size: var(--font4--size);
     margin-bottom: 20px;
     display: flex;
     align-items: center;
 }

 .contact-details .details-item .icon-con svg {
     fill: #FFFFFF;
 }

 .contact-details .details-item .icon-con {
     border: 2px dashed #FFFFFF;
     max-width: 42px;
     max-height: 42px;
     min-width: 42px;
     min-height: 42px;
     border-radius: 50%;
     margin-right: 8px;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .contact-details .details-item a,
 .contact-details .details-item span {
     color: #FFFFFF;
 }

 @keyframes float {
     0% {
         transform: translateY(0px);
     }

     50% {
         transform: translateY(-20px);
     }

     100% {
         transform: translateY(0px);
     }
 }

 @media (max-width: 991px) {

     .conatact-form,
     .contact-container {
         grid-template-columns: 100%;
     }

     .conatact-form .contact-card,
     .conatact-form .cardCon {
         width: 100%;
     }
 }

 .hr {
     display: block;
     position: relative;
     width: 70%;
     height: 12px;
     background-color: var(--primary-color);
     margin: 2em auto;
 }

 .hr::after {
     content: "";
     position: absolute;
     left: 50%;
     bottom: -9px;
     margin-left: -10px;
     border-left: 10px solid transparent;
     border-right: 10px solid transparent;
     border-top: 10px solid var(--primary-color);
 }