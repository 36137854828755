.orgPackUpDiv {
    margin: 20px 8%;
}

.orgPackUpSecDiv {
    border: 1px solid var(--main--border--color);
    border-radius: 10px;
    border-bottom: none;
    padding: 15px 10%;
    box-shadow: var(--box--shadow) 0px 6px 16px;
}

.orgPackUpH1 {
    color: var(--main--font--color);
    font-weight: 600;
    margin-top: 30px;
    font-size: 28px;
    margin-bottom: 20px;
}

.orgPackUpH2 {
    color: var(--main--font--color);
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 15px;
}

.orgPackUpSubmitBtn {
    width: 20%;
    height: 45px;
    margin: 20px 20px 20px 76%;
    padding: 2px 5px 2px 5px;
    background: linear-gradient(
        97.33deg,
        var(--primary-color) -1.25%,
        var(--primary-color-light) 106.84%
    );
    border-radius: var(--main--border--radius);
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
}

.imgDisplayDiv {
    padding: 10px;
}

.wallImgDisp {
    width: 150px;
    height: 150px;
    margin: 10px;
}
