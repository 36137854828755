.orgAccountDiv {
    margin: 20px 8%;
}

.orgAccountSecDiv {
    border: 1px solid var(--main--border--color);
    border-radius: 10px;
    border-bottom: none;
    padding: 15px 10% 15px 3%;
    box-shadow: var(--box--shadow) 0px 6px 16px;
}

.orgAccountH1 {
    color: var(--main--font--color);
    font-weight: 600;
    margin-top: 30px;
    font-size: 28px;
    margin-bottom: 0px;
}

.orgAccEditBtn {
    height: 40px;
    width: 14%;
    margin: 0px 0px 0px 85%;
    padding: 2px 5px 2px 5px;
    background: var(--primary-color);
    border-radius: var(--main--border--radius);
    border: none;
    color: white;
    font-weight: 600;
    cursor: pointer;
}
.orgAccountH2 {
    color: var(--main--font--color);
    font-weight: 600;
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 15px;
}

.orgAccountH2Own {
    color: var(--main--font--color);
    font-weight: 600;
    margin-top: -20px;
    font-size: 20px;
    margin-bottom: 15px;
}

.orgAccountInDiv {
    margin: 0 0 0 10px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 40%;
}

.orgAccountLabel {
    font-size: 12px;
    font-weight: 600;
    color: var(--main--font--color);
    align-content: center;
}

.orgAccounProfiletp {
    padding: 0px 0 5px 0;
    margin: 0px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
}

.orgAccountp {
    padding: 0px 0 5px 0;
    margin: 0px;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
}

.profileDiv {
    display: flex;
    align-items: center;
    gap: 32px;
}

.orgAccountGrid {
    display: flex;
    margin-top: 8px;
    margin-bottom: 15px;
}

.mt-2 {
    margin-top: 8px;
    margin-left: 20px;
}
