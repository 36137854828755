.dialog {
    border: none;
    border-radius: 28px;
    outline: none;
    padding: 40px;
    min-width: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dialog:focus {
    outline: none;
}

.dialogHeading {
    font-weight: 600;
    font-size: 22px;
    color: #14141f;
    text-align: center;
    margin-bottom: 4px;
}
